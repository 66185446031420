<template>
	<div :style="compStyle" style="overflow: auto;" @click="headSelect=null">
		<div ref="querybar" v-show="model.allowQuery==='Y'">
			<reportquery ref="queryRef" @query="toQuery" @setorder="setOrder" @buttonclick="buttonClick" :page="page"
				:project="project" :report-model="model" :get-custom-sql="getCustomSql" :query-set="model.dataSet"
				:params="urlParams" :auto-query="model.autoQuery" :allow-advance="model.advanceQuery==='Y'"
				:allow-head="model.headCustom !='Y'" :allow-order="false" label-align="right"
				:item-width="model.queryItemWidth" :buttons="buttons" :form-do="false"
				:fold-button="model.foldButton==='Y'" :isedit="isedit" />
		</div>

		<div ref="fieldarea" v-if="isedit" style="display:flex; padding: 5px; flex-wrap: wrap; ">
			<div v-for="head in dataRowHead" @click.stop="headClick(head)" style="width:200px;" class="field-row">
				<div class="field-label">
					<i v-if="head.colType==='row'" class="far fa-cubes"></i>
					<i v-else-if="head.colType==='col'" class="far fa-random"></i>
					<i v-else-if="head.colType==='value'" class="far fa-circle"></i>
					<i v-else-if="head.colType==='total'" class="far fa-sigma"></i>
					<i v-else class="far fa-ban"></i>
					{{head.label}}
					<template
						v-if="model.sortRow === 'Y' &&( head.colType==='row') || model.sortCol === 'Y' &&( head.colType==='col')">
						<i v-if="head.sortType==='desc'" class="far fa-sort-amount-down iconbutton"
							@click="setSort(head)"></i>
						<i v-else class="far fa-sort-amount-up-alt iconbutton" @click="setSort(head)"></i>
					</template>

				</div>
				<div v-if="head.colType==='total'" class="field-set">
					<el-dropdown trigger="hover" @command="setTotalfield($event,head)">
						<i class="far fa-cog " title="设置汇总指标"></i>
						<template #dropdown>
							<el-dropdown-menu>
								<template v-for="dataItem in dataRowHead">
									<el-dropdown-item v-if="dataItem.colType==='value'" :command="dataItem.fieldName">
										<span>
											<i
												:class="head.totalCol===dataItem.fieldName?'far fa-check':'far fa-circle'"></i>
											<el-link :underline="false">{{dataItem.label}} </el-link>
										</span>
									</el-dropdown-item>

								</template>
								<el-dropdown-item divided command="$$delete">
									<span>
										<i class="far fa-times"></i>
										<el-link :underline="false">移除此汇总项 </el-link>
									</span>
								</el-dropdown-item>
							</el-dropdown-menu>
						</template>
					</el-dropdown>
					<!-- <i class="far fa-times" @click.stop="setTotalfield('$$delete',head)" title="移除此汇总项"></i> -->
				</div>
				<div v-else class="field-set">

					<el-dropdown trigger="hover" @command="fieldMenuClick($event,head)">
						<i class="far fa-cog" title="设置字段类型"></i>
						<template #dropdown>
							<el-dropdown-menu>

								<el-dropdown-item command="row">
									<span>
										<i class="far fa-cubes"></i>
										<el-link :underline="false">行维度 </el-link>
									</span>
								</el-dropdown-item>
								<el-dropdown-item command="col">
									<span>
										<i class="far fa-random"></i>
										<el-link :underline="false">列维度 </el-link>
									</span>
								</el-dropdown-item>
								<el-dropdown-item command="value">
									<span>
										<i class="far fa-circle"></i>
										<el-link :underline="false">指标值 </el-link>
									</span>
								</el-dropdown-item>
								<!-- 	<el-dropdown-item command="total">
									<span>
										<i class="far fa-sigma"></i>
										<el-link :underline="false">汇总值 </el-link>
									</span>
								</el-dropdown-item> -->
								<el-dropdown-item command="none">
									<span>
										<i class="far fa-ban"></i>
										<el-link :underline="false">不启用 </el-link>
									</span>
								</el-dropdown-item>
							</el-dropdown-menu>
						</template>
					</el-dropdown>



				</div>

			</div>
		</div>
		<div ref="tablearea" class=" table-area">
			<!-- <button @click="toExportPdf">导出PDF</button> -->
			<table ref="listTable" :border="model.lineShow=='Y'?1:0" class="tablelist" cellspacing="0" cellpadding="0">
				<!-- 动态行纬度的表头 -->
				<tr v-for="(head,index) in crossTab.headRows" @click.stop="headClick(head.field)"
					:style="{backgroundColor:head.field.bgColor?head.field.bgColor:model.headBgColor,fontSize:head.field.fontSize,fontWeight:head.field.fontBold,zIndex:10-index}"
					class="tabletop">
					<td v-if="index<1" :colspan="crossTab.rowFields.length" :rowspan="crossTab.headRows.length"
						class="diagonal-background fix-col">
						<div :style="joinAreaStyle">
							<div style="height:50%;text-align: right;padding-right: 5px;">
								{{model.colText}}
								<i v-if="isedit" class="far fa-layer-plus iconbutton" @click.stop="addTotalCol(null)"
									title="增加全局汇总项"></i>

							</div>
							<div
								style="display:flex; height:50%;padding-left: 5px;text-align: left; align-items: end; ">
								{{model.rowText}}
							</div>
						</div>
					</td>
					<template v-for="col in head.cols">
						<td v-if="col.rowSpan>0 && col.colSpan>0" :colspan="col.colSpan" :rowspan="col.rowSpan"
							:style="{color:head.field.fontColor,backgroundColor:col.totalField?col.totalField.bgColor:null,minWidth:col.totalField?col.totalField.minWidth:null}">
							{{col.label}}
							<!-- 	最后一行的列纬度上不用加汇总列 -->
							<template v-if="isedit && index<crossTab.headRows.length -1">
								<i class="far fa-layer-plus iconbutton" @click="addTotalCol(head)" title="增加列纬度汇总"></i>
							</template>
						</td>
					</template>


				</tr>
				<!-- 		表格的指标行表头 -->
				<tr class="tabletop table-row" :style="{backgroundColor:model.headBgColor,zIndex:10}">
					<td v-for="col in crossTab.rowFields" class="fix-col" @click.stop="headColClick(col)"
						:style="{width:col.width,minWidth:col.minWidth,backgroundColor:model.headBgColor,textAlign:'center'}">
						<div class="row-field-cell">
							{{col.label}}
						</div>

					</td>
					<template v-for="col in crossTab.dataCols">
						<td v-if="col.colSpan>0" @click.stop="headColClick(col)"
							:style="{width:col.field.width,minWidth:col.field.minWidth,backgroundColor:col.field.bgColor?col.field.bgColor:model.headBgColor,color:col.field.fontColor,minWidth:col.field?col.field.minWidth:null,textAlign:'center'}">
							<!-- 汇总表头列 -->

							<i v-if="isedit && col.totalField && !col.totalField.totalCol"
								class="far fa-question iconbutton" title="请设置汇总指标"></i>{{col.label}}
						</td>
					</template>

				</tr>
				<!-- 		表格的数据 -->
				<tr v-for="(row,index) in crossTab.tableRows" class="table-row">
					<template v-for="col in row.cols">
						<td v-if="col.span>0" :rowspan="col.span" class="fix-col">


							<div class="row-field-cell" :style="{justifyContent:col.field.hAlign}">
								{{col.value}}
							</div>

						</td>
					</template>

					<td v-for="dataCol in crossTab.dataCols" :style="{textAlign:dataCol.field.hAlign}"
						class="data-cell">
						{{row.dataRow[dataCol.key] }}
					</td>
				</tr>
				<!-- 	<tr class="tabletop">

						<template v-for="head in dataRowHead">
							<td :ref="'head'+head.id" @click.stop="headClick(head)" class="datacol noselect"
								:style="{width:head.width,minWidth:head.minWidth || model.colMinWidth ,fontFamily:head.fontName,fontSize:head.fontSize,color:head.fontColor,fontWeight:head.fontBold,backgroundColor:head.bgColor}">
								<div :class="headSelect===head?'current-col':''">
									<div style="width:calc(100% - 6px);">
										{{head.label}}
									</div>
									<div @mousedown="headSizeStart($event,head)" style="width:6px;cursor:e-resize;">
									</div>
								</div>

							</td>
						</template>

					</tr>
					<tr v-for="row in sampleList">
						<template v-for="head in dataRowHead">
							<td>
								{{row[head.fieldName]}}

							</td>
						</template>
					</tr>
 -->
			</table>

		</div>




	</div>
</template>

<script>
	import jsPdf from 'jspdf'
	import htmlTableToExcel from 'html-table-to-excel'
	import * as XLSX from 'xlsx'
	import html2canvas from 'html2canvas'
	import util from '../../../../../plugins/logicutil.js'
	import TableHead from './TableHead.vue'
	import ReportQuery from './ReportQuery.vue'
	import formBase from '../../../formbase.js'
	export default {
		mixins: [formBase],
		emits: ['dataload'],
		data() {
			return {
				currentPage: 1,
				currentRow: null,
				urlParams: {},
				headSelect: null,
				dataRows: [],

				crossTab: {
					dataCols: [], //数据列集合
					rowFields: [], //用于生成表格的行维度的列头
					headRows: [], //表格行维度的行集合，带跨列
					tableRows: [], //
					//dataList: [] //数据集合，行数与tableRows相等

				}

			}
		},

		methods: {
			toExportPdf() {
				let doc=new jsPdf()
				console.log(doc)
			 
				doc.html(this.$refs.listTable, {
					callback: (doc) => {

						doc.save("page.pdf")

					}
				})
			},
			toExportExcel() {
				htmlTableToExcel(this.$refs.listTable, this.model.name)

				/* 	return
					// 创建一个工作簿  
					const wb = XLSX.utils.book_new();

					// 转换HTML表格到工作表  
					const ws = XLSX.utils.table_to_sheet(this.$refs.listTable, {
						sheet: 'sheet1'
					});

					// 添加工作表到工作簿  
					XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

					// 生成Excel文件并触发下载  
					XLSX.writeFile(wb, '数据.xlsx'); */

			},
			buttonClick(button) {
				if (button.key === 'img') {
					//开始遮罩
					this.$logic.showLayer(true, true)
					setTimeout(() => {
						this.exportImg()
					}, 10)
				} else if (button.key === 'excel') {
					this.toExportExcel()
				}

			},

			exportImg() {
				//截取完整内容的关键，尺寸取外部容器，截图的对象取容器内未滚动的的元素
				let area = this.$refs.tablearea
				let option = {
					width: area.scrollWidth,
					height: area.scrollHeight,
					windowHeight: area.scrollHeight,
					windowWidth: area.scrollWidth,
					x: 0,
					y: 0,
				}
				let start = new Date().getTime()
				html2canvas(this.$refs.listTable, option).then(canvas => {
					this.$logic.showLayer(false)
					// 转换Canvas为图片  
					let image = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
					// 触发下载  
					let link = document.createElement('a');
					link.download = this.model.name + '.png';
					link.href = image;
					link.click();
					//	console.log((new Date().getTime() - start) / 1000)
				})

			},
			setSort(headCol) {
				headCol.sortType = headCol.sortType === 'asc' ? 'desc' : 'asc'

				this.toCrossData(this.model.sampleList)
			},
			addTotalCol(headCol) {

				let totalItem = this.elementConfig.create('crosshead', null, this.page.formData)
				totalItem.userMode = 'Y' //用户添加的字段项，查询设置中不进行清除
				totalItem.colType = 'total'
				totalItem.totalType = 'sum'
				if (headCol) {
					totalItem.label = headCol.field.label + '汇总'
					totalItem.belongCol = headCol.field.fieldName
				} else {
					totalItem.label = '全局汇总'
					totalItem.belongCol = ''
				}

				totalItem.name = totalItem.label
				//数据类型为空，需要在从指示项中获取设置
				this.model.dataSet.headItems.push(totalItem)
				this.$logic.tip.success('已创建汇总项：' + totalItem.label)
				this.toCrossData(this.model.sampleList)
			},
			setTotalfield(fieldName, totalItem) {
				if (fieldName === '$$delete') {
					for (let i = 0; i < this.dataRowHead.length; i++) {
						let item = this.dataRowHead[i]
						if (item === totalItem) {
							this.model.dataSet.headItems.splice(i, 1)
							break
						}
					}
					this.toCrossData(this.model.sampleList)
				}
				for (let item of this.dataRowHead) {
					if (item.fieldName === fieldName) {
						totalItem.totalCol = item.fieldName
						totalItem.typeGroup = item.typeGroup
						totalItem.unionType = item.unionType
						totalItem.dataType = item.dataType
						this.toCrossData(this.model.sampleList)
						break
					}
				}


			},
			fieldMenuClick(type, head) {
				head.colType = type
				this.toCrossData(this.model.sampleList)
			},

			toCrossData(dataList) { //根据数据库查询出来的数据生成交叉表数据
				let list = [] //数据行
				let tableRows = [] //表格行
				let rowFields = [] //行纬度的字段
				let colFields = [] // 列纬度的字段
				let valueFields = [] //指标的字段
				let totalFields = [] //统计值的字段

				let colFieldValuePaths = {} //列维度的所有值出现过的组件

				//滚动条先复位
				let tableArea = this.$refs.tablearea
				tableArea.scrollLeft = 0



				for (let head of this.dataRowHead) {
					switch (head.colType) {
						case 'row':
							rowFields.push(head)
							break
						case 'col': //要考虑两级转置的问题，目前只考虑一级转置
							colFields.push(head)
							head.$valueList = [] //按顺序装载列维度的值
							head.$valueMap = {} //判断值是否存在

							break
						case 'value':
							valueFields.push(head)
							break
						case 'total':
							/* 	if (head.totalCol) {  //totalCol有指向指标数据的汇总项才生效								
									
								} */
							totalFields.push(head)
							break

					}
				}
				if (rowFields.length < 1) {
					return
				}
				/* if (colFields.length != 1) {
					this.$logic.tip.error('转置字段设置错误')
					return
				} */
				let startTime = new Date().getTime()
				let lastTime = startTime
				//原数据排序
				if (this.model.sortRow === 'Y') {
					let pingYinCatch = {}
					dataList.sort((obj1, obj2) => {
						let rs = 0
						for (let field of rowFields) {
							let v1 = obj1[field.fieldName]
							let v2 = obj2[field.fieldName]
							if (v1 === v2) {

							} else {
								//值不同的情况下文本类型值取拼音
								let p1 = v1
								let p2 = v2
								if (field.sortPingyin === 'Y') {
									if (v1 && typeof(v1) === 'string') {
										if (v1 in pingYinCatch) {
											p1 = pingYinCatch[v1]
										} else {
											p1 = this.$logic.pingyin.getPinyin(v1, '', false, false)
											pingYinCatch[v1] = p1
										}

									}
									if (v2 && typeof(v2) === 'string') {
										if (v2 in pingYinCatch) {
											p2 = pingYinCatch[v2]
										} else {
											p2 = this.$logic.pingyin.getPinyin(v2, '', false, false)
											pingYinCatch[v2] = p2
										}
									}
								}
								if (field.sortType === 'asc') {
									rs = p1 > p2 ? 1 : -1
								} else {
									rs = p1 < p2 ? 1 : -1
								}

								break
							}
						}
						return rs
					})
					//lastTime = new Date().getTime()
					//console.log(lastTime - startTime)
				}
				//console.log(dataList.length)
				//
				let rowMap = {} //行指标为KEY的结果行数据,用map取行就不需要原数据排序
				let preRow = null
				for (let data of dataList) {
					/* let isNew = false
					if (preRow) { //当前行数据与上一行对比，如果分组列一致，处理上一行的数据，否则创建新行						
						for (let group of groupFields) {
							if (data[group.fieldName] != preRow[group.fieldName]) {
								isNew = true
								break
							}
						}
					} else {
						isNew = true
					}
					if (isNew) {
						
					} 	 */
					let rowKey = ''
					for (let field of rowFields) {
						rowKey = rowKey + ',' + data[field.fieldName]
					}
					preRow = rowMap[rowKey]
					if (!preRow) { //添加新的数据行
						preRow = {}
						for (let field of colFields) { //好像已没有用，可去掉
							preRow[field.fieldName] = data[field.fieldName]
						}
						rowMap[rowKey] = preRow
						list.push(preRow)
						//同时增加表格行维数据
						let tbRow = {
							dataRow: preRow,
							cols: []
						}
						for (let field of rowFields) {
							tbRow.cols.push({
								value: data[field.fieldName],
								span: 0,
								field: field
							})
						}
						tableRows.push(tbRow)
					}
					//行上增加新的列,列的每层数据+指标名称作为key值
					let colPath = ''

					for (let col of colFields) { //取列纬度的值，将对应到横向的多层表头
						let colValue = data[col.fieldName]
						colPath = colPath + ',' + colValue
						//将所有出现过的路径组合都加入Map,用于后面过滤不存在的列

						if (!(colPath in colFieldValuePaths)) {

							colFieldValuePaths[colPath] = colFieldValuePaths
						}
						if (!(colValue in col.$valueMap)) { //if (!(colPath in col.$valueMap)) {

							col.$valueMap[colValue] = colValue
							let colItem = {
								colPath: colPath,
								value: colValue,
								pingyin: this.$logic.pingyin.getPinyin(colPath, '', false, false) //用于排序
							}
							//各列纬度出现的值放在各列的值集合内，应该要增加排序
							let idx = -1

							if (this.model.sortCol === 'Y') {
								for (let i = 0; i < col.$valueList.length; i++) {
									//if (colPath.localeCompare( col.$valueList[i].colPath) < 0) {
									let v1 = colItem.value
									let v2 = col.$valueList[i].value
									if (col.sortPingyin === 'Y') {
										v1 = colItem.pingyin
										v2 = col.$valueList[i].pingyin
									}

									let comp = col.sortType === 'asc' ? colItem.pingyin < col.$valueList[i]
										.pingyin : colItem.pingyin > col.$valueList[i].pingyin
									if (comp) {
										idx = i
										break
									}
								}
							}

							if (idx > -1) {
								col.$valueList.splice(idx, 0, colItem)
							} else {
								col.$valueList.push(colItem)
							}

						}


					}
					//取指标的字段，联合生成新的数值列的
					for (let field of valueFields) {
						let valueKey = colPath + ',' + field.fieldName
						let dataValue = data[field.fieldName]
						this.colTotal(field, preRow, valueKey, dataValue)

						/*
							if (field.typeGroup == 'num') { //处理当前值,确保数据存下数值型的值,后面考虑转整数
								if (dataValue) {
									if (typeof dataValue != "number") {
										dataValue = parseFloat(dataValue)
									}
								} else {
									dataValue = 0
								}
							} else if (valueKey === null) {
								dataValue = '' //其它类型:文本/时间,null转空文本
							}
							if (valueKey in preRow) { //key已存在，进行汇总计算
								let totalValue = preRow[valueKey]
								if (field.totalType === 'sum') {
									if (field.typeGroup == 'num') {
										preRow[valueKey] = totalValue + dataValue
									} else if (field.typeGroup == 'text') {
										preRow[valueKey] = totalValue + ',' + dataValue
									} else { //其它类型按字符串处理
										preRow[valueKey] = dataValue
									}
								} else if (field.totalType === 'max') {
									if (dataValue > totalValue) {
										preRow[valueKey] = totalValue
									}
								} else if (field.totalType === 'min') {
									if (dataValue < totalValue) {
										preRow[valueKey] = totalValue
									}
								} else if (field.totalType === 'first') { //前面已经有值是否存在的判断，不作处理

								} else if (field.totalType === 'last') { //直接后面的覆盖前面的
									preRow[valueKey] = totalValue
								}

							} else { //不存在的直接填值
								preRow[valueKey] = dataValue //非数字存在原始值

							} */
					}

				}

				//生成列的统计数据
				/* 	for (let field of totalFields) {
						if (!field.totalCol) {
							continue
						} //先设置好关联字段
						for (let valuefield of valueFields) {
							if (field.totalCol === valuefield.id) {
								field.$totalCol = valuefield
							}
						}
						if (!field.$totalCol) {
							continue
						}
						let colPath = ''
						for (let colField of colFields) {
							colPath = colPath + ',' + colField.fieldName
							if (field.belongCol === colField.id) {
								field.$belongCol = colField
							}
						}
						//field.$dataKey = colPath + ',' + field.$totalCol.fieldName

					} */


				//生成多层的数据表头
				let headRows = []
				for (let i = 0; i < colFields.length; i++) {
					let col = colFields[i] //从各列维度生成数据表头
					let headRow = {
						field: col,
						cols: []
					}
					headRows.push(headRow)

					if (i > 0) { //
						let upHead = headRows[i - 1] //取到上面的行
						for (let parentCol of upHead.cols) {

							let preKey = parentCol.key + ',' //取归于上级列下的数据项生成下级的表头列
							for (let valueItem of col.$valueList) {
								/* let dataKey=preKey+valueItem.value//如果值名称路径下存在值，加入些列的集合
								console.log(dataKey) */
								let dataKey = preKey + valueItem.value
								//console.log(dataKey) //valueItem.colPath
								if (dataKey in
									colFieldValuePaths) { //if (valueItem.colPath.indexOf(preKey) === 0) {
									headRow.cols.push({
										parent: parentCol,
										rowSpan: 1, //正常列纬度单元行每都有
										leafs: 0,
										label: valueItem.value,
										key: dataKey

									})
								}
							}
							if (parentCol.totalField) { //如果上一层的此列是汇总列，下面增加一个空标签的汇总列
								headRow.cols.push({
									totalField: parentCol.totalField,
									rowSpan: 0, //上级继承上级的汇总列，单元格将被合并
									parent: parentCol,
									leafs: 0,
									label: parentCol.label, // parentCol.totalField.label, //继承下来的汇总列没有文本
									key: parentCol.key //汇总的数据层次向下传递
								})
							} else { //如果上一层是正常的列纬度
								//看是否在这一层的列加上一级的汇总列，汇总列在指向的归属列纬度的下一级中增加横向列
								for (let totalField of totalFields) {
									if (totalField.belongCol === upHead.field.fieldName) {

										headRow.cols.push({

											totalField: totalField, //汇总的指定字段,有此属性为汇总列
											rowSpan: colFields.length - i, //从此行开始，下面的单元格将被合并
											parent: parentCol,
											leafs: 0,
											label: parentCol.label, // totalField.label,
											key: parentCol.key //指向数值KEY的层次//+ ',' + totalField.totalCol
										})
									}
								}
							}

						}




					} else {

						for (let valueItem of col.$valueList) {
							headRow.cols.push({
								parent: null, //上层
								rowSpan: 1,
								leafs: 0,
								label: valueItem.value,
								key: ',' + valueItem.value
							})
						}
						//如果未指定归属列纬度，在全局添加汇总项
						for (let totalField of totalFields) {
							if (!totalField.belongCol) {
								headRow.cols.push({
									totalField: totalField, //汇总的指定字段,有此属性为汇总列
									rowSpan: colFields.length, //表头总行数为列纬度作数+数据名称行
									parent: null, //上层
									leafs: 0,
									label: '', // totalField.label,
									key: ',' //指向数值KEY的层次//+ ',' + totalField.totalCol
								})
							}
						}
					}

				}

				//从最下层的列向上推算上层各列的叶子数，同时生成数据列
				let dataCols = []
				let totalCols = []
				if (headRows.length > 0) { // 
					let lastRow = headRows[headRows.length - 1]

					//console.log(lastRow)
					for (let col of lastRow.cols) { //生成全部指标数据表单集合
						if (col.totalField) { //汇总列只增加一列
							let totalItem = {
								totalField: col.totalField,
								colSpan: 1, //增加列数据但在前端不出来此列表头
								keyPath: col.key === ',' ? col.key : col.key + ',', //增加一个结束符，用于直接匹配需汇总的数据项
								key: col.key + ',' + col.totalField
									.id, //用totalField可能会同一个指标多种汇总的情况有冲突, //自身显示的数据
								label: col.totalField.label,
								field: col.totalField
							}
							dataCols.push(totalItem)
							totalCols.push(totalItem) //加入汇总列集合
							col.leafs = 1
						} else {
							for (let valueField of valueFields) {
								dataCols.push({
									isData: true, //标识是数据列
									colSpan: 1,
									key: col.key + ',' + valueField.fieldName,
									label: valueField.label,
									field: valueField //数据项的字段
								})
							}

							col.leafs = valueFields.length //最下分组的叶子数量即为指标个数
						}

						let parent = col.parent
						while (parent) {

							parent.leafs = parent.leafs + col.leafs
							parent = parent.parent

						}
					}
				}
				//处理汇总数据

				for (let row of tableRows) {
					let data = row.dataRow
					for (let totalCol of totalCols) {
						if (!totalCol.field.totalCol) { //没有指定汇总指标的不计算，但是在表格中出现，因此在列表中

							continue
						}
						let totalStart = false
						for (let col of dataCols) {
							//console.log(col.key,totalCols.keyPath)
							if (col.isData && col.key.indexOf(totalCol.keyPath) === 0) { //如果是汇总列查找相应的数据
								totalStart = true
								if (totalCol.field.totalCol === col.field.fieldName) {
									let dataValue = data[col.key]
									this.colTotal(totalCol.field, data, totalCol.key, dataValue)

								}

							} else {
								if (col.isData && totalStart) { //满足统计条件的列是连续的，如果已经开始统计，发现第一个不在统计范围的数据跳过

									//break
								}
							}
						}
					}

				}

				//列维度相同表头内容合并

				for (let headRow of headRows) {
					let leftCol = {
						parent: null,
						label: null
					}
					for (let i = 0; i < headRow.cols.length; i++) { //从左向右找相同的，有相同的就把左侧的colSpan置零，右侧的累计
						let col = headRow.cols[i]
						col.colSpan = col.leafs
						if (col.leafs === 1) {
							col.colSpan = 1 //默认置为叶子数
							if (col.label === leftCol.label && col.parent === leftCol.parent) {
								let leftCol = headRow.cols[i - 1]
								col.colSpan = leftCol.colSpan + 1
								leftCol.colSpan = 0

							}
						}
						leftCol = col
					}
				}
				//console.log(headRows[headRows.length-1].cols)
				//console.log(dataCols)

				//行维度数据的合并处理,每一列从下往上推，行合并数量
				if (this.model.spanRow === 'Y') {
					for (let i = tableRows.length - 1; i > -1; i--) {
						let row = tableRows[i]
						let preRow = i < tableRows.length - 1 ? tableRows[i + 1] : null
						//let path = ''
						for (let j = 0; j < row.cols.length; j++) {
							let col = row.cols[j]
							col.span = 1 //默认当前列是未合并
							if (preRow) {
								if (col.value === preRow.cols[j].value) {
									//判断前面的列的值是否相同
									let checkPath = true
									for (let k = j - 1; k > -1; k--) {
										if (preRow.cols[k].value != row.cols[k].value) {
											checkPath = false
											break
										}
									}
									if (checkPath) {
										col.span = preRow.cols[j].span + 1
										preRow.cols[j].span = 0 //跨行数累加到上行后，上行的跨行数置为零，在表格中不出来
									}

								} else {
									//col.span = 1
								}
							} else { //最底部行的列的跨行数为1

							}
						}


					}
				}

				lastTime = new Date().getTime()
				//console.log('数据量：' + dataList.length + '，用时：' + (lastTime - startTime) / 1000)
				let result = {
					rowFields: rowFields,
					headRows: headRows,
					tableRows: tableRows,
					//dataList: list,
					dataCols: dataCols

				}
				this.crossTab = result
				//console.log(result)
				//console.log(list)
				setTimeout(() => {
					this.setLayout(rowFields)
				}, 1500)
			},

			setLayout(rowFieldList) { //延迟之后调用此方法,设置各区域宽度
				let rowFields = rowFieldList ? rowFieldList : null
				if (!rowFields) {
					rowFields = []
					for (let head of this.dataRowHead) {
						if (head.colType == 'row') {
							rowFields.push(head)
						}
					}
				}
				let queryBar = this.$refs.querybar
				let tableArea = this.$refs.tablearea
				let fieadArea = this.$refs.fieldarea
				let table = this.$refs.listTable
				if (!queryBar || !tableArea || !table) {

					return
				}
				let queryHeight = queryBar.offsetHeight + 10 + (fieadArea ? fieadArea.offsetHeight : 0)
				tableArea.style.height = 'calc(100% - ' + queryHeight + 'px)'
				let scrollLeft = tableArea.scrollLeft
				let rows = table.querySelectorAll('tr.tabletop')
				let sum = 0
				if (rows && rows.length > 1) {
					for (let row of rows) { //上一行的高度是下一行的偏移值
						row.style.top = sum + 'px'
						sum = sum + row.offsetHeight
					}

				}

				//设置列冻结的偏移量,左侧的结点有合并，每行单元格数量不一定相同，对每行的起始单元格作偏移计算
				rows = table.querySelectorAll('tr.table-row') //指标行与数据行
				if (rows && rows.length > 1) { //第0行是表头，有行数据的情况下才处理
					//let offLeft = rows[1].cells[0].offsetLeft //数据行会多出左侧偏移量，取第一行的第一格的左边距就是偏移量
					//console.log(offLeft)
					let firstRow = rows[0]
					let tds = []
					let leftSum = 0
					let tdCount = firstRow.cells.length

					for (let i = 0; i < rowFields.length; i++) {
						//.offsetLeft - scrollLeft //如果当前发生了滚动，offsetLeft会多出滚动偏移量，需扣除
						tds.push({
							left: leftSum
						})
						leftSum = leftSum + firstRow.cells[i].offsetWidth
					}
					for (let r = 0; r < rows.length; r++) { //左侧有可能会有合并单元格，对每行的行维度字段设置左侧偏移量
						let row = rows[r]
						let start = tdCount - row.cells.length
						for (let j = start; j < tds.length; j++) {
							let left = tds[j].left
							row.cells[j - start].style.left = left + 'px'
						}

					}

				}

			},
			colTotal(field, dataRow, valueKey, dataValue) {
				//let dataValue = data[field.fieldName]
				if (field.typeGroup == 'num') { //处理当前值,确保数据存下数值型的值,后面考虑转整数
					if (dataValue) {
						if (typeof dataValue != "number") {
							dataValue = parseFloat(dataValue)
						}
					} else {
						dataValue = 0
					}
				} else if (dataValue === null || (typeof(dataValue) === 'undefined')) {
					dataValue = '' //其它类型:文本/时间,null转空文本
				}
				//let rs = null
				if (valueKey in dataRow) { //key已存在，进行汇总计算
					let totalValue = dataRow[valueKey]

					if (field.totalType === 'sum' || field.totalType === 'join') {

						if (field.typeGroup == 'num') {
							//rs = totalValue + dataValue

							dataRow[valueKey] = totalValue + dataValue
						} else if (field.typeGroup == 'text') {
							//rs = totalValue + ',' + dataValue
							dataRow[valueKey] = totalValue + ',' + dataValue
						} else { //其它类型按字符串处理
							//rs = dataValue
							dataRow[valueKey] = dataValue
						}
					} else if (field.totalType === 'max') {
						if (dataValue > totalValue) {
							//rs = dataValue
							dataRow[valueKey] = dataValue
						}
					} else if (field.totalType === 'min') {
						if (dataValue < totalValue) {
							//rs = dataValue
							dataRow[valueKey] = dataValue
						}
					} else if (field.totalType === 'first') { //前面已经有值是否存在的判断，不作处理

					} else if (field.totalType === 'last') { //直接后面的覆盖前面的
						//rs = dataValue
						dataRow[valueKey] = dataValue
					}

				} else { //不存在的直接填值
					//rs = dataValue //非数字存在原始值
					dataRow[valueKey] = dataValue
				}
				//dataRow[valueKey] = rs
				//return rs
			},

			///////////////////////////////////////////////////////////////////////////////////////////



			headSizeStart(event, head) {
				/* 	this.resizeHead = head
					let headRef = this.$refs['head' + head.id] 
					this.resizeWidth = headRef.offsetWidth  
					this.resizeX1 = event.screenX
					 */
			},
			headColClick(colItem) {

				let field = colItem.field || colItem
				this.headClick(field)
			},
			headClick(head) {
				if (this.isedit) {
					head.parent = this.model
					this.headSelect = head
					this.setCurrentElement(head)
				}
			},
			setOrder() {
				this.model.dataSet.orderItems = list
			},
			getCustomSql(normalMap = {}) { //查询组件的回调值，固定查询和虚拟查询项
				if (this.isedit) {
					return null
				} else {
					return this.doEvent({
						eventName: 'getSql',
						params: normalMap
					}, '$comp_getSql')
				}
			},
			callQuery(params, fromPage1 = true) {
				if (fromPage1) {
					this.currentPage = 1
				}
				if (params && typeof(params) === 'object') { //传参
					for (let key in params) {
						this.urlParams[key] = params[key]
					}
				}
				if (this.$refs.queryRef) {
					this.$refs.queryRef.getQuery()
				}

			},
			toQuery(sql = null, params = null) {
				if (this.isedit) {
					return
				}
				if (!sql || sql.length < 6) {
					return
				}
				if (sql) { //SQL发生改变重置为第一页
					this.sql = sql
					this.currentPage = 1
				}
				if (params) {
					this.params = params
				}
				this.doQuery()
				//console.log(sql)
			},
			doQuery(pageNo) { // 此类报表当前页终为1
				if (this.isedit) {
					return
				}

				this.dataRows = []

				let isTotal = false //this.model.pageType === 'num'
				let option = {
					isJson: true,
					total: isTotal,
					size: this.model.maxSize || 10000,
					pageNo: this
						.currentPage,
					showLayer: true
				}
				//console.log(this.sql)
				this.$logic.http.sqlQuery(this.dbSourceKey, this.sql, this.params, option)
					.then(res => {
						/* let c = ((this.currentPage > 0 ? this.currentPage : 1) - 1) * this.pageSize
					

						let total = parseInt(res.data.data.totalAll)
						if (total > -1) {
							this.rowsTotal = total
						}
 */
						this.setList(res.data.data.dataList)
						//this.$emit('dataload', res.data.data)
					}).catch(err => {
						let info = err.info ? err.info : err.message
						this.$logic.tip.error('数据查询处理异常：' + info)
					})

			},
			/* 		getList(onlySeleted = false) {
						let list = null
						if (onlySeleted) {
							list = []
							for (let item of this.dataRows) {
								if (item.$check) {
									list.push(item)
								}
							}
						} else {
							list = this.dataRows
						}
						return list
					}, */
			setList(list, c = 0) { //先得到格式化的行数据
				if (list && 'length' in list) {

					let ds = this.model.dataSet
					for (let item of list) {
						/* item.$check = false //这些属性预留
						item.$edit = false
						item.$rowNo = ++c
						item.$cellModel = {
							$fold: false

						} */
						//创建model副本
						/* 	let excludFields = ['parent', '$linkObj', '$sqlLink']
						let refsFields = ['$linkObj', '$sqlLink']
						if (this.model.allowFold === 'Y') {
							item.$cellModel.$foldPanel = this.$logic.clone.deepClone(this.model.dataSet
								.foldPanel, excludFields, refsFields
							)
							//console.log(this.model.dataSet.foldPanel)
							this.$logic.clone.setParent(item.$cellModel.$foldPanel, item)

						}
						if (this.model.customSet === 'Y') {
							item.$cellModel.$itemPanel = this.$logic.clone.deepClone(this.model.dataSet
								.itemPanel,
								excludFields, refsFields)
							this.$logic.clone.setParent(item.$cellModel.$itemPanel, item)
						}


						for (let head of ds.headItems) {
							if (head.mode === 'text') {
								item[head.fieldName] = util.getFormatText('text', head.dataType, head
									.format, item[
										head.fieldName])
							} else if (head.mode === 'num') {
								item[head.fieldName] = util.getFormatNum('num', head.dataType, head
									.format, item[
										head.fieldName])
							} else if (head.mode === 'time') {
								item[head.fieldName] = util.getFormatTime('time', head.dataType, head
									.format, item[
										head.fieldName])
							}
						}
 */


					}
					this.currentRow = null
					this.dataRows = list
					//this.setData('pageDataCount', list.length)



					this.doEvent({ //数据加载完成的事件中可让用户对数据再进行处理
						eventName: 'dataLoad',
						dataList: list,
					}, '$comp_dataLoad')

					//生成统计报表
					this.toCrossData(list)

				} else {
					this.$logic.tip.error('无效的表格数据赋值，请使用数组类型数据')
				}

			},

			setRecall(dataSet, sampleList) {

				for (let key in dataSet) {
					this.model.dataSet[key] = dataSet[key]
				}

				for (let i = 0; i < this.model.dataSet.headItems.length; i++) {
					let head = this.model.dataSet.headItems[i]
					head.type = 'crosshead'
					if (!head.colType) { //初始字段项
						//head.colType = 'row'
						head.colType = i === 0 ? 'row' : 'none'
						head.sortType = 'asc'
						head.sortPingyin = 'N'
					}
					/* if (head.mode === 'custom' && head.panel && head.panel.type) { //如果存在有效的自定义元素
						head.panel.parent = this.model
					} */
				}
				if (sampleList && sampleList.length > 0) { //接收样本数据
					this.model.sampleList = sampleList
				}
				this.$nextTick(() => {
					this.toCrossData(this.model.sampleList)
				})

				return true
			},
		},
		computed: {
			joinAreaStyle() {
				let css = {
					width: '100%',
					height: '100%'
				}
				if (this.model.joinBgImg) {
					let url = this.model.joinBgImg
					let project = this.project
					url = project && url.startsWith('@') ? '/upload/' + project.unitGroup +
						'/' +
						project.unitId + '/' + project.id + url.substring(1) : url
					url = 'url(' + this.$logic.http.baseUrl + url + ')'
					css['background-image'] = url
					css['background-size'] = '100% 100%'

				}
				return css
			},
			sampleList() {
				return this.model.sampleList || []
			},
			dataRowHead() {
				return this.model.dataSet.headItems || []
			},
			buttons() {
				let bs = []
				try {
					let data = JSON.parse(this.model.buttons)
					for (let item of data) { //head,order,add,view,edit,del
						if (item.key && ',query,super,img,excel,'.indexOf(',' + item.key +
								',') >
							-1) {
							let key = item.key + 'Button'
							if (this.model[key] === 'Y') {
								bs.push(item)
							}
						} else {
							if (this.model.allowButton == 'Y') {
								bs.push(item)
							}
						}
					}

				} catch (ex) {
					this.$logic.tip.error('报表按钮数据定义格式错误：' + ex)
				}
				return bs

			},
			dbSourceKey() {
				return this.model.dataSet.dataSourceKey ? this.model.dataSet.dataSourceKey : this.project
					.dataSource.sourceKey
			},
		},
		components: {
			//	reportset: ReportSet,
			reportquery: ReportQuery,
			tablehead: TableHead,
		},
		watch: {
			'$store.state.resizeTick'(nv, ov) { //APP.vue中设置window.onresize监听事件
				this.setLayout(null)
			}
		},
		mounted() {
			if (this.isedit) {
				this.toCrossData(this.model.sampleList || [])
			} else {

			}
		},
		created() {
			//this.$logic.security.encodeSql2('abc')
			//this.$logic.security.getChart62(98)

			if (this.isedit) {
				this.model.$setRecall = this.setRecall
			} else {
				this.urlParams = this.page.$params || {}
				this.model.$query = this.callQuery
				/* 
				 //程序调用点击查询按钮
				this.model.$sqlUpdate = this.sqlUpdate */
			}

		}
	}
</script>

<style scoped>
	.desion-left {
		height: 100%;
		width: 200px;
		border: solid 1px #cccccc;
		overflow: auto;

	}

	.desion-right {
		height: 100%;
		width: calc(100% - 204px);
		border: solid 1px #cccccc;
		overflow: auto;
	}

	.field-title {
		background-color: #f8f8f8;
		padding: 5px;

	}

	.field-row {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border: solid 1px #cccccc;
		border-radius: 5px;
		margin: 2px;
		padding: 5px;
		cursor: pointer;
	}

	.field-row:hover {
		border-style: dashed‌;
		background-color: #f8f8f8;
	}

	.field-label {
		width: calc(100% - 20px);
		overflow: hidden;
	}

	.field-set {
		width: 20px;
		text-align: right;
	}

	/* 	.field-set i {
		margin-right: 5px;
	}

	.field-set i:hover {
		color: #FF4500;
	} */
	.iconbutton {
		margin: 0px 5px;
		cursor: pointer;
	}

	.iconbutton:hover {
		color: #FF4500;
	}

	.diagonal-background {
		background: linear-gradient(to top right, var(--join-back-color) 49%, var(--table-line-color) 49%, var(--join-back-color) 51%);

		background-size: auto 100%;
		background-repeat: no-repeat;
		box-shadow: 0px 0px 0px 0.5px var(--table-line-color) inset;

		color: var(--join-font-color);
		font-size: var(--join-font-size);
	}

	.table-area {
		overflow: auto;
		border: solid 0.2px var(--table-line-color);

	}

	.tablelist {
		min-width: 100%;
		height: 20px;
		/* 不从父窗口中继承高度 */
		border-collapse: collapse;
		border-spacing: 0px;
		border-color: var(--table-line-color);
	}

	/*.tablelist td {
		 border-color: var(--table-line-color);
		color: var(--table-text-color); 

	}*/

	.fix-col {
		position: sticky;
		position: -webkit-sticky;
		left: 0px;
		background-color: #ffffff;


	}

	.tabletop {
		/* firefox加了这种属性后设置了背景色表格线不显示 */
		position: sticky;
		position: -webkit-sticky;
		top: 0px;

		/*  */
	}

	.tablelist .fixtd {
		position: sticky;
		left: 0px;
		/* z-index: 1; */


	}

	.tabletop>td {
		height: var(--head-height);
		text-align: center;

	}

	.tabletop>.datacol {

		min-width: var(--col-min-width);
		/* 		background: linear-gradient(#f5f5f5, #e7e7e7, #f5f5f5);
 */
		/* 	border-left: solid 1px var(--table-line-color);
 */
		cursor: pointer;
	}

	/* 	.tabletop div {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: var(--title-back-color);
		border-top: solid 1px var(--table-line-color);
		border-bottom: solid 1px var(--table-line-color);
		color: var(--title-text-color);

	} */
	.table-row {
		height: var(--row-height);

	}

	.table-row .data-cell {
		padding: var(--row-padding-top) var(--row-padding-right) var(--row-padding-bottom) var(--row-padding-left);

	}

	.table-row:nth-of-type(odd) {
		background-color: var(--row-split-color);
	}

	.table-row:hover {
		background-color: var(--row-hover-color);
	}


	.row-field-cell {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		border-right: solid 1px var(--table-line-color);

	}


	.total-col-head {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center
	}

	.current-col {
		--title-back-color: #FF4500;
		background: linear-gradient(#ffdddd, #FF4500, #ffdddd);
		color: #ffffff;
	}
</style>