import font from '../../public/font.js' 
import options from '../../public/options.js'
import compBase from '../../public/comp.js'
let bold=[{label:'常规',value:''},{label:'加粗',value:'bold'}]
let italic=[{label:'常规',value:''},{label:'斜体',value:'italic'}]
let decoration=[{label:'常规',value:''},{label:'下划线',value:'underline'},{label:'删除线',value:'line-through'},{label:'闪烁',value:'blink'},{label:'上划线',value:'overline'}]
/* let fonts=[
	{label:'默认',value:''},	{label:'宋体',value:'SimSun'},{label:'黑体',value:'SimHei'},
{label:'微软雅黑',value:'Microsoft YaHei'},{label:'微软正黑',value:'Microsoft JhengHei'},
{label:'楷体',value:'KaiTi'},{label:'仿宋',value:'FangSong'},{label:'新宋体',value:'NSimSun'},
{label:'仿宋_GB2312',value:'FangSong_GB2312'},{label:'楷体_GB2312',value:'KaiTi_GB2312'},
{label:'新细明体',value:'PMingLiU'},{label:'细明体',value:'MingLiU'},{label:'标楷体',value:'DFKai-SB'}
]
let size=[{label:'默认',value:''}]
for(let i=5;i<81;i++){
	size.push({label:i+'号',value:i+'px'})
}
 */
let nodeFont=[
	{key:'title',text:'节点样式'},
//	{key:'areaWidth',text:'目录区宽度',type:'text',init:'250px',target:'--node-area-width',bursh:true,bind:true,tip:'',list:null},
	
	{key:'iconColor',text:'图标颜色',type:'color',init:'#1E90FF',target:'--node-icon-color',bursh:true,bind:true,tip:'',list:null},
	{key:'indent',text:'缩进距离',type:'number',init:25,target:'',bursh:true,bind:true,tip:'',list:null},
	{key:'nodeH',text:'横向对齐',type:'select',init:'left',target:'',bursh:true,bind:true,tip:'',list: options.LCR},
	{key:'nodeV',text:'纵向对齐',type:'select',init:'middle',target:'',bursh:true,bind:true,tip:'',list: options.TMB},
	
	{key:'nfontName',text:'节点字体',type:'select',init:'',target:'--node-font-name',bursh:true,bind:true,tip:'',list:options.fonts},
	{key:'nfontSize',text:'节点字号',type:'select',init:'',target:'--node-font-size',bursh:true,bind:true,tip:'',list:options.fontSizes},	
	{key:'nfontColor',text:'节点颜色',type:'color',init:'#1E90FF',target:'--node-font-color',tip:'',bursh:true,bind:true,list:null},	
	{key:'nfontBold',text:'节点粗体',type:'select',init:'',target:'--node-font-weight',bursh:true,bind:true,tip:'',list:bold},
	{key:'nbackColor',text:'节点背景色',type:'color',init:'#F4F4F4',target:'--node-back-color',bursh:true,bind:true,tip:'选择颜色或输入渐变色',list:null},
	
]
let dataFont=[
	{key:'title',text:'数据样式'},
	{key:'dataH',text:'横向对齐',type:'select',init:'right',target:'',bursh:true,bind:true,tip:'',list: options.LCR},
	{key:'dataV',text:'纵向对齐',type:'select',init:'middle',target:'',bursh:true,bind:true,tip:'',list: options.TMB},
	
	{key:'dfontName',text:'数据字体',type:'select',init:'',target:'--data-font-name',bursh:true,bind:true,tip:'',list:options.fonts},
	{key:'dfontSize',text:'数据字号',type:'select',init:'',target:'--data-font-size',bursh:true,bind:true,tip:'',list:options.fontSizes},	
	{key:'dfontColor',text:'数据颜色',type:'color',init:'',target:'--data-font-color',tip:'',bursh:true,bind:true,list:null},	
	{key:'dfontBold',text:'数据粗体',type:'select',init:'',target:'--data-font-weight',bursh:true,bind:true,tip:'',list:bold},
	{key:'dbackColor',text:'数据背景色',type:'color',init:null,target:'--data-back-color',bursh:true,bind:true,tip:'选择颜色或输入渐变色',list:null},
	
]


let colls=[{label:'折叠',value:'collapse'},{label:'不折叠',value:''}] 
let oprations=[{label:'可编辑',value:'edit'},{label:'只读',value:'read'}] 
let sumLayer=[{label:'不自动汇总',value:''}
//,{label:'叶子节点汇总',value:'leaf'}
,{label:'逐级汇总',value:'all'}] 

let dataSet=  options.getDataSet([]) //{dataSourceKey:'',sql:'',headItems:[],queryItems:[],orderItems:[],tick:0}

let cs=[
{key:'title',text:'表格属性'},
{key:'dataSet',text:'查询选项',type:'report',init:dataSet,target:'',bursh:true,bind:true,tip:'',list: null},
{key:'autoQuery',text:'自动查询',type:'switch',init:'Y',target:'',bursh:true,bind:true,tip:'加载后自动查询，否则需手工点击查询按钮执行查询',list: options.YON},

{key:'allowQuery',text:'允许查询',type:'switch',init:'Y',target:'',bursh:true,bind:true,tip:'',list: options.YON},
{key:'queryItemWidth',text:'查询项宽度',type:'text',init:'100px',target:'',bursh:true,bind:true,tip:'文本框等输入元素的宽度，日期时间输入框不受此影响',list: options.YON},
{key:'allowButton',text:'扩展按钮',type:'switch',init:'N',target:'',bursh:true,bind:true,tip:'是否附加自定义按钮',list: options.YON},

{key:'allowOrder',text:'允许排序',type:'switch',init:'Y',target:'',bursh:true,bind:true,tip:'',list: options.YON},
{key:'advanceQuery',text:'可高级查询',type:'switch',init:'Y',target:'',bursh:true,bind:true,tip:'',list: options.YON},
{key:'headCustom',text:'定制标题栏',type:'switch',init:'N',target:'',bursh:true,bind:true,tip:'',list: options.YON},

//{key:'opration',text:'操作方式',type:'select',init:'read',target:'',bursh:true,bind:true,tip:'',list: oprations},
{key:'total',text:'数据汇总',type:'switch',init:'Y',target:'',bursh:true,bind:true,tip:'',list:  options.YON},
{key:'foldWidth',text:'目录区宽度',type:'text',init:null,target:'--node-area-width',bursh:true,bind:true,tip:'',list: null},
{key:'foldMinWidth',text:'目录最小宽',type:'text',init:'300px',target:'--node-area-minwidth',bursh:true,bind:true,tip:'目录区在此最小宽度的基础上自动拉伸,如需固定宽度请在设置目录区宽度',list: null},
{key:'colMinWidth',text:'列最小宽度',type:'text',init:'100px',target:'--col-min-width',bursh:true,bind:true,tip:'各数据列在此最小宽度的基础上自动拉伸,如需固定宽度请在列属性中设置列宽度',list: null},
{key:'expandLayer',text:'展开层级',type:'number',init:1,target:'',bursh:true,bind:true,tip:'默认展开层级',list: null},

//{key:'nodeAct',text:'节点折叠',type:'select',init:'Y',target:'',bursh:true,bind:true,tip:'',list: options.YON},

//{key:'dataAct',text:'数据交互',type:'select',init:'N',target:'',bursh:true,bind:true,tip:'',list: options.YON},
{key:'headHeight',text:'表头行高',type:'text',init:'40px',target:'--head-height',bursh:true,bind:true,tip:'',list: null},

{key:'trH',text:'表体行高',type:'text',init:'35px',target:'--row-height',bursh:true,bind:true,tip:'',list: null},
{key:'minW',text:'最小宽度',type:'text',init:'500px',target:'minWidth',bursh:true,bind:true,tip:'用于兼容移动端宽度自适应,象素、百分比、函数类型',list:null},
{key:'minH',text:'最小高度',type:'text',init:'100px',target:'minHeight',tip:'象素、百分比、函数类型',bursh:true,bind:true,list:null},	

//{key:'lineShow',text:'显示网格线',type:'select',init:'Y',target:'',bursh:true,bind:true,tip:'',list: options.YON},
{key:'hoverColor',text:'悬浮颜色',type:'color',init:'#FAFAD2',target:'--hover-color',bursh:true,bind:true,tip:'选择颜色',list:null},	 
{key:'lineColor',text:'斑马线颜色',type:'color',init:'#fafafa',target:'--line-color',bursh:true,bind:true,tip:'选择颜色',list:null},	 

{key:'borderColor',text:'网格颜色',type:'color',init:'#909399',target:'borderColor',bursh:true,bind:true,tip:'选择颜色',list:null},	 	 
{key:'lineSize',text:'网格线粗',type:'number',init:'1',target:'',bursh:true,bind:true,tip:'',list: null},
//{key:'lineColl',text:'线条折叠',type:'select',init:'collapse',target:'border-collapse',bursh:true,bind:true,tip:'',list: colls},

//{key:'datas',text:'数据集合',type:'text',init:null,target:'',bursh:false,bind:false,tip:'',list:null},	
{key:'width',text:'宽度',type:'text',init:'calc(100% - 2px)',target:'width',bursh:true,bind:true,tip:'',list: null},
{key:'height',text:'高度',type:'text',init:'100%',target:'height',bursh:true,bind:true,tip:'',list: null},



]
let buttons =JSON.stringify( [
	//['文本','图标', '样式','状态' ], 
	{key:'query',text:'查询', icon:'far fa-search',type:'primary',enabled:'Y',loading:'N'},
	{key:'super',text:'高级', icon: 'far fa-search-plus',type: 'success',enabled:'Y',loading:'N' },
	{key:'head',text:'列表', icon: 'far fa-list',type: 'primary',enabled:'Y',loading:'N' },
	{key:'order',text:'排序', icon: 'far fa-sort-amount-up-alt',type: 'primary' ,enabled:'Y',loading:'N'},
	
/* 	{key:'add',text:'创建', icon:'far fa-plus',type:'success',enabled:'Y',loading:'N'},
	{key:'view',text:'查看', icon: 'far fa-file-search',type: 'default',enabled:'Y',loading:'N' },
	{key:'edit',text:'编辑', icon: 'far fa-edit',type: 'primary',enabled:'Y',loading:'N' },
	{key:'del',text:'删除', icon: 'far fa-times',type: 'danger' ,enabled:'Y',loading:'N'}, */
	{key:'upload',text:'自定义', icon: 'far fa-upload', type:'info',enabled:'Y',loading:'N'  },
])
let inputOption=options.getInputOption()
inputOption.jsonArray.heads=[
	{key:'key',label:'按钮标识',type:'text',default:'',list:[],field:'',width:'16%',unique:true},
	{key:'text',label:'按钮文本',type:'text',default:'',list:[],field:'',width:'16%',unique:false},
	{key:'icon',label:'按钮图标',type:'icon',default:'',list:[],field:'',width:'16%',unique:false},
	{key:'type',label:'按钮样式',type:'select',default:'',list:compBase.colorTypes,field:'',width:'16%',unique:false},
	{key:'enabled',label:'可用状态',type:'switch',default:'Y',list:[],field:'',width:'16%',unique:false},
	{key:'loading',label:'加载状态',type:'switch',default:'N',list:[],field:'',width:'16%',unique:false},
]
let special=[
 
	{key:'title',text:'查询按钮'},
	{key:'queryButton',text:'启用查询',type:'switch',init:'Y',target:'',bursh:true,bind:true,tip:'内置功能key值为query的按钮',list: options.YON},
	{key:'superButton',text:'启用高级',type:'switch',init:'Y',target:'',bursh:true,bind:true,tip:'内置功能key值为super的按钮',list: options.YON},
	{key:'headButton',text:'启用列表',type:'switch',init:'Y',target:'',bursh:true,bind:true,tip:'内置功能key值为head的按钮',list: options.YON},
	{key:'orderButton',text:'启用排序',type:'switch',init:'Y',target:'',bursh:true,bind:true,tip:'内置功能key值为order的按钮',list: options.YON},
	
	{key:'allowButton',text:'按钮扩展',type:'switch',init:'N',target:'',bursh:true,bind:true,tip:'是否附加自定义按钮',list: options.YON},
	
	{key:'foldButton',text:'折叠按钮',type:'switch',init:'N',target:'',bursh:true,bind:true,tip:'按钮数量两个以上时，折叠以菜单方式显示',list: options.YON},
	
	{key:'dataOption',text:'选项设置',type:'inputset',init:inputOption,target:'',bursh:true,bind:false,tip:'',list: null},
	{key: 'buttons',text: '自定义按钮',type: 'jsonArray',init: buttons,	target: '',bursh: true,bind: true,tip: '',attribute:'dataOption'},// ,treeMap:{id:'key',label:'text',icon:'icon'},dataMap: {key:'唯一标识',text:'按钮文本',icon:'图标',type:'外观样式',enabeld:'可用状态',loading:'加载状态'}
	
]

let events=[
	{key: '$comp_filter',text: '数据过滤',type: 'event',init: 'N',target: '',	bursh: true,bind: false,tip: '修改或增加数据项，干预处理当前所有数据',	list: null	},
	{key: '$comp_nodeClick',text: '节点点击',type: 'event',init: 'N',target: '',	bursh: true,bind: false,tip: '',	list: null	},
	{key: '$comp_dataClick',text: '数据点击',type: 'event',init: 'N',target: '',	bursh: true,bind: false,tip: '',	list: null	},
	{key: '$comp_getSql',text: '动态SQL',type: 'event',init: 'N',target: '',	bursh: true,bind: false,tip: '动态变更SQL,返回数据结果中的字段应与初始设定中的SQL结果一致',	list: null	},
	{key: '$comp_buttonClick',text: '按钮点击后',type: 'event',init: 'N',target: '',	bursh: true,bind: false,tip: '点击扩展按钮后触发，可对数据内容进行干预处理',	list: null	},

]
let treetable= {
	data:{
		type:'foldtable',
	},
	delete:'',
	hide:'width,height,minH,',
	base:cs.concat(nodeFont).concat(dataFont) ,
	special: special,

	action:events
}

export default treetable