import options from '../../public/options.js'
import compBase from '../../public/comp.js'
import extend from '../../public/extend.js'


let base = [
	{key: 'title',text: '发票识别'	},
    {key: 'apiKey',text: 'ApiKey',type: 'text',init: '',	target: '',bursh: true,bind: true,tip: '',list: null},
 //   {key: 'title',text: '扫一扫'	},   
    {key: 'doType',text: '识别类型',type: 'select',init: 'multiple',	target: '',bursh: true,bind: true,tip: '',list: options.toOptions('通用批量发票=multiple')},
    {key: 'resultTo',text: '结果回填',type: 'text',init: '',	target: '',bursh: true,bind: true,tip: '',list: null},
 
 
	]
	
let actions=[
	{key: '$comp_scanFinish',text: '扫码完成',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '从返回的code值判断成功或失败',list: null	},
	//{key: '$comp_scanError',text: '扫码失败',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '',list: null	},
	
	
]
	
let faPiao = {
	data: {
		type: 'fapiao',
	},
	delete: '',
	hide: '',
	base: base,//.concat(compBase.base)
	special: [],
	action:  options.getCommonEvents().concat(actions).concat(options.getEvents(null,'$hook'))
}

export default faPiao