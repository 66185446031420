<template>

	<div ref="pb" v-print="printOption" v-show="false"></div>

	<div v-show="model.show !=='none' " @click="mouseClick" :style="containerStyle">
		<template v-if="isedit">
			<div class="tickbar" :style="{width:pageSize.widthPxS}">
				<div v-for="item in sizeTick" :style="item">
					<div :style="{marginLeft:item.ml}">{{item.label}}</div>
					<div style="height:5px;width:1px" class="printtick"></div>
				</div>
			</div>
			<div ref="printArea" :id="areaId" @mouseover="mouseOver" @mouseleave="mouseLeave" :style="printStyle"
				:class="printcss">
				<div v-if="printHead" class="print-head" :style="headStyle">
					<container :model="printHead" :project="project" :page="page" :host="host" :isedit="isedit"
						:datas="bindData" />
				</div>
				<div v-if="printBody" class="print-body" :style="{height: pageSize.bodyHeightPxS ,width:'100%'   }">

					<container :model="printBody" :project="project" :page="page" :host="host" :isedit="isedit"
						:datas="bindData" />
				</div>
				<div v-if="printFoot" class="print-foot" :style="footStyle">
					<container :model="printFoot" :project="project" :page="page" :host="host" :isedit="isedit"
						:datas="bindData" />

				</div>


			</div>

		</template>




		<div v-else ref="printArea" :id="areaId" @click="mouseClick" @mouseover="mouseOver" @mouseleave="mouseLeave"
			:style="printStyle" :class="printcss">

			<!-- 整个内容包在一个TABLE里可现每页内容的上下固定间距 -->

			<table border="0" style="width: 100%;border: 0px; ">
				<!-- 每页都会有这个重复内容 利用此机制 占位，给页眉留出位置 只接受样式直接赋值 -->
				<thead>
					<tr :style="{height:pageSize.emptyHeadMmS}">
						<td>

						</td>
					</tr>
				</thead>
				<!-- start: 正文 -->
				<tbody>
					<tr>
						<td>
							<container v-if=" printBody && printBody.group==='layout'" :model="printBody"
								:project="project" :page="page" :host="host" :isedit="isedit" :datas="bindData" />



						</td>
					</tr>
				</tbody>
				<!-- 	页脚占位区 此部分会每页重复出现 -->
				<tfoot>
					<tr :style="{height:pageSize.emptyFootMmS}">
						<td>



						</td>
					</tr>
				</tfoot>
			</table>

		</div>

		<div v-if="!isedit && printHead" ref="printHead" class="print-head-run"
			:style="{height:pageSize.head.heightMmS,width:pageSize.bodyWidthMmS,left:pageSize.pageLeftMm + 'mm'}">
			<container :model="printHead" :project="project" :page="page" :host="host" :isedit="isedit"
				:datas="bindData" />
		</div>
		<div v-if="!isedit && printFoot" ref="printFoot" class="print-foot-run"
			:style="{height:pageSize.foot.heightMmS,width:pageSize.bodyWidthMmS,left:pageSize.pageLeftMm + 'mm'}">
			<container :model="printFoot" :project="project" :page="page" :host="host" :isedit="isedit"
				:datas="bindData" />
		</div>
	</div>
</template>

<script>
	import layoutBase from './layoutBase.js'
	import html2canvas from 'html2canvas'
	import jsPDF from 'jspdf'

	export default {
		mixins: [layoutBase],
		data() {
			return {
				headTop: 0, //页面头部留出6毫米空白
				areaId: null,
				bindData: null,
				pageLastItems: [], //每页的最后一个元素集合,附加了换页标记
				pageNumberItems: [], //每页的页码元素集合
				pageSizeMap: {
					A3: {
						width: 297,
						height: 420
					},
					A4: {
						width: 210,
						height: 297
					},
					A5: {
						width: 148,
						height: 210
					},
					A6: {
						width: 105,
						height: 144
					}
				}

			}
		},

		methods: {
			clearBreak() {
				//清除已加的附加元素 
				let panel = this.$refs.printArea
				for (let item of this.pageLastItems) {
					item.style['page-break-before'] = null
				}
				for (let item of this.pageNumberItems) {
					panel.removeChild(item)
				}
				this.pageLastItems = []
				this.pageNumberItems = []
			},
			createBar(type, innerHtml, i, pageSum) {
				let newObj1 = document.createElement('div') //用于生成绝对定位的页眉页脚区块
				newObj1.classList.add('print-page-' + type) //class在public.css定义，在这里定义的class名称并不是运行后的名称
				newObj1.style.position = 'absolute'
				newObj1.style.height = this.pageSize[type].heightMmS //this.pageSize.footHeightMms
				newObj1.style.width = this.pageSize.bodyWidthMmS
				newObj1.style.left = this.pageSize.pageLeftMm + 'mm'
				newObj1.style.overflow = 'hidden'
				if (type === 'head') {
					newObj1.style.top = ((i) * this.pageSize.heightMm + this.pageSize.pageTopMm) + 'mm'
				} else {
					newObj1.style.top = ((i + 1) * this.pageSize.heightMm - this.pageSize.emptyFootMm) + 'mm'
				}
				let currentPage = '' + (i + 1)
				newObj1.innerHTML = innerHtml.replace(/\#pages/g, pageSum).replace(/\#page/g, currentPage)
				return newObj1
			},
			createPageNumber() { //计算内容区高度转换的页数，为每页创建一个页码
				let panel = this.$refs.printArea
				let printHead = this.$refs.printHead
				let printFoot = this.$refs.printFoot
				let headHtml = printHead ? printHead.innerHTML : null
				let footHtml = printFoot ? printFoot.innerHTML : null
				//console.log(printFoot.innerHTML)

				let pageHeight = this.pageSize.bodyHeightPx //页内容的高度				
				let conentHeight = panel.offsetHeight
				//let scrollTop = window.scrollY || document.documentElement.scrollTop;
				let pageSum = Math.ceil(conentHeight / pageHeight)
				for (let i = 0; i < pageSum; i++) {
					if (this.model.headShow === 'Y') {
						let newHead = this.createBar('head', headHtml, i, pageSum)
						panel.appendChild(newHead)
						this.pageNumberItems.push(newHead)
					}
					if (this.model.footShow === 'Y') {
						let newFoot = this.createBar('foot', footHtml, i, pageSum)
						panel.appendChild(newFoot)
						this.pageNumberItems.push(newFoot)
					}

				}
			},
			//停用此方法，使用浏览器自带的分页机制即可
			pageBreak() { //对内容进行分页,非页面高度，以内容高度分隔
				let model = this.model
				let headHeight = this.printHead ? this.pageSize.head.height : 0
				let footHeight = this.printFoot ? this.pageSize.foot.height : 0

				let panel = this.$refs.printArea //document.getElementById(this.areaId)
				// parseInt(this.printStyle.minHeight.replace('px', '')) - headHeight - footHeight -30 //留一点余量
				let pageHeight = this.pageSize.height - this.pageSize.emptyHeight

				let conentHeight = panel.offsetHeight
				let scrollTop = window.scrollY || document.documentElement.scrollTop;
				let pageSum = Math.ceil(conentHeight / pageHeight)

				let stack = [panel]
				let leafs = []
				while (stack.length > 0) {
					let node = stack.pop()
					//console.log(node.tagName)
					//	let top = node.offsetTop
					let height = node.offsetHeight

					if (node.children && node.children.length > 0 && height > pageHeight / 4) { // && node.tagName != 'TR'
						for (let i = node.children.length - 1; i > -1; i--) {
							stack.push(node.children[i])
						}
					} else {
						let rect = node.getBoundingClientRect();
						//let scrollTop = window.scrollY || document.documentElement.scrollTop;
						let top = rect.top + scrollTop;
						leafs.push({
							node: node,
							top: top,
							height: node.offsetHeight
						})
					}

				}
				leafs.sort((obj1, obj2) => {
					return obj1.top - obj2.top
				})

				let spitNodes = []


				let rect = panel.getBoundingClientRect();
				let topBase = rect.top + scrollTop;

				let objIndex = 0
				for (let i = 0; i < pageSum; i++) {
					let pageBottom = topBase + i * pageHeight + pageHeight
					while (objIndex < leafs.length) {
						let node = leafs[objIndex]
						objIndex++
						let nodeBottom = node.top + node.height
						if (nodeBottom > pageBottom) { //取第一个超出本页的范围的元素，需分割到下一页的元素

							node.node.style['page-break-before'] = 'always' //在这个元素的前面插入分页符
							let newObj1 = document.createElement('div') //用于绝对定位
							newObj1.classList.add('print-page-foot') //class在public.css定义，在这里定义的class名称并不是运行后的名称
							newObj1.style.position = 'absolute'
							newObj1.style.right = '10px'
							newObj1.style.top = ((i + 1) * this.pageSize.heightMm - 5) +
								'mm' // (i * pageHeight + pageHeight - 20) + 'px'
							newObj1.textContent = '这是粗体文本（不会加粗）' + (i + 1)
							panel.appendChild(newObj1)
							this.pageLastItems.push(node.node)
							this.pageNumberItems.push(newObj1)


							//node.node.style['page-break-after'] = 'always'
							//回溯找到前面第一个在当前面的元素，插入print-page-index class
							/*
							if (objIndex > 2) {
								let preNode = leafs[objIndex - 2]
								preNode.node.style['page-break-after'] = 'always'				
 

							}
*/
							break
						}
					}

				}

			},

			print2() {
				this.pageBreak()
				return
				this.toImg()
			},
			toImg() {
				let area = this.$refs.printArea
				let option = {
					width: area.scrollWidth,
					height: area.scrollHeight,
					windowHeight: area.scrollHeight,
					windowWidth: area.scrollWidth,
					x: 0,
					y: 0,
				}
				let start = new Date().getTime()
				html2canvas(area, option).then(canvas => {
					//this.$logic.showLayer(false)
					let imgData = canvas.toDataURL("image/png")
					let img = new Image();
					img.onload = () => {
						console.log(img.width, img.height)
						// 转换Canvas为图片  
						const pdf = new jsPDF({
							orientation: 'portrait',
							unit: 'px',
							format: [img.width, img.height] // Custom format to match image size  
						})
						const rows = 3; // Number of rows (chunks vertically)  
						const chunkSize = Math.ceil(img.height / rows);

						for (let i = 0; i < rows; i++) {
							const chunkCanvas = document.createElement('canvas');
							chunkCanvas.width = img.width;
							chunkCanvas.height = chunkSize;
							const chunkCtx = chunkCanvas.getContext('2d');

							// Draw the chunk onto the new canvas  
							chunkCtx.drawImage(
								canvas,
								0, i * chunkSize, img.width, chunkSize, // Source rectangle  
								0, 0, img.width, chunkSize // Destination rectangle  
							);

							// Add the chunk to a new PDF page  
							const imgData = chunkCanvas.toDataURL(
								'image/png'); //.replace("image/png",	"image/octet-stream");
							/* let link = document.createElement('a');
							link.download = this.model.name + '.png';
							link.href = imgData;
							link.click();
							return */

							pdf.addImage(imgData, 'PNG', 0, 0, img.width, chunkSize);
							pdf.setFontSize('20');
							//pdf.setFontStyle('normal');
							pdf.setFillColor('#ff0000');
							pdf.text('TEST', 100, 50);
							pdf.addPage();
						}

						// Remove the last added page (it's an extra one)  
						pdf.deletePage(pdf.internal.getNumberOfPages());

						// Save the PDF  
						//pdf.save('image_chunks.pdf');
						// 将PDF输出为数据URI（base64编码）  
						const pdfDataUri = pdf.output('datauristring');

						// 在新窗口中打开PDF以供打印  
						const printWindow = window.open('', '_blank');
						printWindow.document.write(
							`<iframe width="100%" height="100%" src="${pdfDataUri}"></iframe>`);
						printWindow.document.close();
						printWindow.focus(); // 可选：将焦点设置到新窗口上  






						/* let image = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
						// 触发下载  
						let link = document.createElement('a');
						link.download = this.model.name + '.png';
						link.href = image;
						link.click(); */
						//	console.log((new Date().getTime() - start) / 1000)

					}


					img.src = imgData;

				})
			},

			delay(ms) {
				return new Promise(resolve => {
					setTimeout(resolve, ms);
				})
			},
			async autoLayout() { //调整表格到合适的位置，避免跨行
				if (this.model.autoRow != 'Y') {
					return
				}
				var panel = document.getElementById(this.areaId);
				if (!panel) {
					return
				}
				let dpi = this.model.dpi || 96
				let padV = this.headTop * 2 * 1123 / 297 * (dpi / 96)
				let pageHeight = this.pageSize.height - padV //打印页内容区总高度
				var elements = panel.querySelectorAll('tr');
				//let elementsHeight=[]

				for (let i = 0; i < elements.length; i++) {
					let xPosition = 0;
					let yPosition = 0;
					let element = elements[i]
					let curentNode = element
					while (element && element != panel) {
						xPosition += (element.offsetLeft - element.scrollLeft + element.clientLeft);
						yPosition += (element.offsetTop - element.scrollTop + element.clientTop);

						element = element.offsetParent;
					}

					let rowHeightMin = curentNode.offsetHeight - 10
					let ph = yPosition % pageHeight
					if ((pageHeight - ph) < rowHeightMin) { //当前行的位置不足以完整显示全自己

						//curentNode.style.backgroundColor = 'red'
						if (i > 0) {
							let preTr = elements[i - 1]
							let oldHeight = preTr.offsetHeight
							let newHeigtht = parseInt(oldHeight + (pageHeight - ph))
							preTr.style.height = newHeigtht + 'px'
							//console.log(i + 1, oldHeight, newHeigtht, (pageHeight - ph))
							//await this.delay(500); //延时等页面渲染,实际并没有发生作用，后面把preTr.style.height移动定时器内切换线程执行
						}
						//break;

					}

				}

			},
			async toPrint() { //berforeRecall,afterRecall
				/* 	let ck = this.doEvent({
						eventName: 'printbefore',
						printId: '#' + this.model.id
					}, '$comp_printBefore', this.bindData)
					 */
				let ck = this.doEvent({
					eventName: 'printbefore',
					printId: '#' + this.model.id
				}, '$comp_printBefore')
				if (ck === false) {
					return
				}

				//this.pageBreak()


				if (this.model.show === 'show') {
					//await this.autoLayout()
					this.createPageNumber()
					this.$refs.pb.click()
				} else { //如果一开始是隐藏模式，图表可能尺寸需要重置
					this.model.show = 'show'
					setTimeout(() => {
						let resized = this.$logic.pageUtil.reSizeDom(this.model, true)
						let times = resized ? 200 : 10 //等待图表画完后打印
						setTimeout(() => {
							this.createPageNumber()
							this.$refs.pb.click()
							if (this.model.autoHide === 'Y') {
								this.model.show = 'none'
							}

						}, times)
					}, 200)


				}



			},

			beforeOpenCallback(param) { //弃用

			},
			openCallback(param) { //弃用

			},
			closeCallback(param) {
				this.clearBreak()
				if (this.model.autoHide === 'Y') {
					this.model.show = 'none'
				}
				this.doEvent({
					eventName: 'printafter',
					printId: '#' + this.model.id
				}, '$comp_printAfter', this.bindData)
			},
			setPageSize() {
				let page = this.pageSizeMap[this.model.pageType]
				if (page) {
					if (this.model.direction == 'V') {
						this.model.pageWidth = page.width
						this.model.pageHeight = page.height
					} else {
						this.model.pageWidth = page.height
						this.model.pageHeight = page.width
					}

				}

			},
			getPxFromMm(mm) {
				let dpi = this.model.dpi || 96
				let px = mm ? mm : 0
				//A4纸96dpi下的尺寸210*297 分辨率是794×1123
				px = parseInt(794 / 210 * px * (dpi / 96)) //等比例换算象素值
				return px
			}



		},
		computed: {
			printHead() {
				return this.model.headShow === 'Y' && this.model.items[1] ? this.model.items[1] : null
			},
			printBody() {
				return this.model.items[0] ? this.model.items[0] : null
			},
			printFoot() {
				return this.model.footShow === 'Y' && this.model.items[2] ? this.model.items[2] : null
			},
			headStyle() {
				let pageSize = this.pageSize
				let css = {
					height: pageSize.head.heightPxS,
					width: pageSize.bodyWidthPxS
				}

				return css
			},
			footStyle() {
				let pageSize = this.pageSize
				let css = {
					height: pageSize.foot.heightPxS,
					width: pageSize.bodyWidthPxS
				}

				return css
			},
			containerStyle() {
				let size = this.pageSize
				let style = {
					width: '100%',
					position: 'relative'
				}
				style['--print-head-empty'] = size.emptyHeadMmS
				style['--print-foot-empty'] = size.emptyFootMmS
				style['--print-top-pad'] = size.pageTopMm + 'mm'
				style['--print-bottom-pad'] = size.pageBottomMm + 'mm'

				return style
			},
			printStyle() {
				let model = this.model
				let style = this.layoutstyle
				let size = this.pageSize
				//计算宽度
				/* let pl = parseInt(model.paddingL || '0px')
				let pr = parseInt(model.paddingR || '0px')
				let pt = parseInt(model.paddingT || '0px')
				let pb = parseInt(model.paddingB || '0px')
				pl = isNaN(pl) ? 0 : pl
				pr = isNaN(pr) ? 0 : pr
				pt = isNaN(pt) ? 0 : pt
				pb = isNaN(pb) ? 0 : pb */

				let pl = size.pageLeftPx
				let pr = size.pageRightPx
				let pt = size.pageTopPx
				let pb = size.pageBottomPx

				/*	let headHeight = model.headHeight * 1
					let footHeight = model.footHeight * 1
					let side = headHeight + footHeight
					let sideHeight = this.getPxFromMm(side) */

				style.width = (size.widthPx - size.pageLeftPx - size.pageRightPx) + 'px'
				//style.minHeight = (size.height - pt - pb  ) + 'px'

				//let padV = this.headTop * 2 //页眉、页脚 高度
				//let dpi = model.dpi || 96
				//A4纸96dpi下的尺寸210*297 分辨率是794×1123				 


				//设计模式固定高度
				if (this.isedit) {
					style.minHeight = (size.heightPx - pt - pb) + 'px'
					style.height = style.minHeight
					style.paddingTop = pt + 'px' //设计时态才有上下边距
					style.paddingBottom = pb + 'px'
				}
				//上下边距
				style.paddingLeft = pl + 'px'
				style.paddingRight = pr + 'px'



				style.position = 'relative'


				return style
			},
			printcss() {
				let css = this.layoutcss
				if (this.isedit) {
					css.push('printcontainer')
				}
				return css
			},
			printOption() {
				let model = this.model
				let printObj = {
					id: this.areaId,
					beforeOpenCallback: this.beforeOpenCallback,
					openCallback: this.openCallback,
					closeCallback: this.closeCallback,
					//其他配置项，
					extraHead: '<meta http-equiv="Content-Language" content="zh-cn"/>', //model.extraHead,
					//header: '<div class="header">我的页眉</div>',
					/* printerName :'Solid Edge Velocity PS Printer 2.0',
					 popTitle: model.popTitle,
					preview: model.preview === 'Y',
					previewTitle: model.previewTitle,
					previewPrintBtnLabel: model.previewPrintBtnLabel */


					popTitle: model.name,

					/*
						 printerName :'printerNamePPPPPPPPPPPP',
						 header: '<div class="header">我的页眉</div>',
						preview: 'previewHHHHHHHHHH',
							 previewPrintBtnLabel: 'previewPrintBtnLabelSSSSSSSSSSSSSS',
					 previewTitle: 'previewTitleAAAAAAAAAAAAA',
						 */
				}
				return printObj

			},
			/* 	pageLines() {
					let lines = []
					pageSize
				}, */
			sizeTick() {
				let ts = []
				let size = this.pageSize.widthMm || 210
				let width = this.pageSize.widthPx
				for (let i = 10; i < size; i = i + 10) {
					let item = {
						left: parseInt(i * width / size) + 'px',
						ml: parseInt(-(width * 10 / 2 / size)) + 'px',
						width: parseInt(width * 10 / size) + 'px',
						label: i,
						position: 'absolute'
					}
					/* if (i % 10 === 0) {
						item.t = i / 10 + 'mm'
					} */
					ts.push(item)
				}
				return ts
			},
			pageSize() {
				let model = this.model
				let widthMm = model.pageWidth || 210
				let heightMm = model.pageHeight || 297
				let dpi = model.dpi || 96
				//A4纸96dpi下的尺寸210*297 分辨率是794×1123
				let widthPx = this.getPxFromMm(widthMm) //等比例换算象素值
				let heightPx = this.getPxFromMm(heightMm)

				// parseInt(1123 / 297 * height * (dpi / 96))

				/* 				let headHeight = parseInt(794 / 210 * model.headHeight * (dpi / 96))
								let footHeight = parseInt(794 / 210 * model.footHeight * (dpi / 96))
								let topPad = parseInt(794 / 210 * model.topPad * (dpi / 96))
								let bottomPad = parseInt(794 / 210 * model.bottomPad * (dpi / 96))
				 */
				let pl = this.getPxFromMm(model.leftPad)
				let pr = this.getPxFromMm(model.rightPad)
				let pt = this.getPxFromMm(model.topPad)
				let pb = this.getPxFromMm(model.bottomPad)

				let headHeight = model.headShow === 'Y' ? model.headHeight * 1 : 0
				let footHeight = model.footShow === 'Y' ? model.footHeight * 1 : 0
				let headHeightPx = this.getPxFromMm(headHeight)
				let footHeightPx = this.getPxFromMm(footHeight)
				let bodyHeightPx = heightPx - pt - pb - headHeightPx - footHeightPx
				let bodyWidthMm = widthMm - model.leftPad * 1 - model.rightPad * 1
				let bodyWidthPx = widthPx - pl - pr
				let size = {
					widthMm: widthMm,
					heightMm: heightMm,
					widthPx: widthPx,
					heightPx: heightPx,
					widthPxS: widthPx + 'px',
					heightPxS: heightPx + 'px',
					pageLeftPx: pl,
					pageRightPx: pr,
					pageTopPx: pt,
					pageBottomPx: pb,
					pageLeftMm: model.leftPad,
					pageRightMm: model.rightPad,
					pageTopMm: model.topPad,
					pageBottomMm: model.bottomPad,
					bodyWidthMmS: bodyWidthMm + 'mm',
					bodyWidthPxS: bodyWidthPx + 'px',
					bodyHeightPx: bodyHeightPx,
					bodyHeightPxS: bodyHeightPx + 'px',
					emptyHeadMm: headHeight + model.topPad * 1,
					emptyFootMm: footHeight + model.bottomPad * 1,
					emptyHeadMmS: (headHeight + model.topPad * 1) + 'mm',
					emptyFootMmS: (footHeight + model.bottomPad * 1) + 'mm',
					emptyHeightMm: headHeight + model.topPad * 1 + footHeight + model.bottomPad * 1,
					head: {
						heightMm: headHeight,
						heightMmS: headHeight + 'mm',
						heightPx: headHeightPx,
						heightPxS: headHeightPx + 'px'
					},
					foot: {
						heightMm: footHeight,
						heightMmS: footHeight + 'mm',
						heightPx: footHeightPx,
						heightPxS: footHeightPx + 'px'
					}
				}

				return size
			}

		},
		watch: {
			'model.pageType'(newValue, oldValue) {

				this.setPageSize()
			},
			'model.direction'(newValue, oldValue) {
				this.setPageSize()
			}
		},
		mounted() {
			/*	if (!this.isedit) {
					this.model.$print = this.toPrint
					  if (this.model.autoHide === 'Y') {//留出时间给图表
						setTimeout(() => {
							this.model.show = 'none'
						}, 10)

					}  
			 	this.doEvent({
					eventName: 'create'
				}, '$comp_loadBefore') 

		}*/
			if (!this.isedit) {
				if (this.model.autoHide === 'Y') {
					this.model.show = 'none'
				}
				/* if (this.model.autoHide === 'Y') {//留出时间给图表
					setTimeout(() => {
						this.model.show = 'none'
					}, 10)
				
				} */
				this.doEvent({
					eventName: 'mounted'
				}, '$comp_loadAfter')
			}
		},
		created() {
			this.areaId = 'print' + new Date().getTime()

			this.createInit()
			if (this.isedit) {


			} else {
				this.model.$print = this.toPrint
				this.model.items[0].height = null //内容区不设置高度，自由扩展
				//this.model.$print2 = this.print2
			}

		}
	}
</script>

<style scoped>
	.printcontainer {
		box-shadow: 0px 0px 0px 1px #cccccc inset;
		background-image: linear-gradient(90deg, rgba(241, 243, 244, 1) 10%, transparent 0),
			linear-gradient(rgba(241, 243, 244, 1) 10%, transparent 0);
		background-size: 10px 10px;




	}

	.tickbar {
		display: flex;
		position: relative;
		height: 25px;
		overflow: hidden;
		background-color: #e8e8e8;
	}



	.printtick {
		background-color: #ccccc;
		border-left: 1px solid #000000;
	}

	.print-head {
		border-left: #cccccc dashed 1px;
		border-right: #cccccc dashed 1px;
		border-top: #cccccc dashed 1px;
		overflow: hidden;
	}

	.print-foot {
		border-left: #cccccc dashed 1px;
		border-right: #cccccc dashed 1px;
		border-bottom: #cccccc dashed 1px;
		overflow: hidden;
	}

	.print-body {
		border: #cccccc 1px dashed;
		overflow: hidden;
	}

	.print-head-run {

		position: absolute;
		top: var(--print-top-pad);
		width: 100%;
		overflow: hidden;

	}

	.print-foot-run {
		position: absolute;
		bottom: var(--print-bottom-pad);
		width: 100%;
		overflow: hidden;
	}

	/* 并没有生效 */
	.print-page-head {
		display: none;
	}

	.print-page-foot {
		display: none;
	}
</style>
<style scoped media="print">
	/* @page {
		size: auto;
		margin-top: 6mm;
		margin-bottom: 6mm;
		margin-left: 0mm;
		margin-right: 0mm;
		page-break-before: always;


	} */


	@media print {
		@page {
			size: auto;
			margin-top: 0mm;
			margin-bottom: 0mm;

			margin-left: 0mm;
			margin-right: 0mm;
			/* 	page-break-before: always; */
			/* counter-reset: print-page-index; */

			/* position: relative; */

		}

		.print-page-head {
			display: block;
		}

		.print-page-foot {
			display: block;
		}

		/* .tablelist td {
			border-width: 1px !important;
		} */

	}
</style>