<template>
	<treeview ref="treeView" :nodes="nodes" :allow-search="model.allowSearch=='Y'" :allow-edit="editButtons.length>0"
		:edit="editButtons" :delete-empty="model.deleteEmpty=='Y'" :allow-check="model.allowCheck=='Y'"
		:allow-drag="model.allowDrag=='Y'" :expand-all="model.expandAll=='Y'" :expand-click="model.expandClick=='Y'"
		:checked="checkedNodes" :expanded="expandedNodes" :accordion="model.accordion=='Y'" @nodeclick="nodeClick"
		:split-char="model.splitChar" @init="init" @add="nodeAdd" @rename="nodeRename" @delete="nodeDelete"
		@nodedrop="nodeDrop" @checkchange="checkChange" :auto-do="false"></treeview>
	<logicdialog v-if="formModel" ref="formEditDialog" @ok="formEditDialogOk" @close="formEditDialogClose"
		@buttonclick="buttonClick" :auto-close="false" :title="buttonType=='add'?'创建新数据':'编辑数据'"
		:dialog-height="model.editHeight" :dialog-width="model.editWidth"
		:icon="buttonType=='add'?'far fa-layer-plus':'far fa-edit'" :show-ok="true"
		:buttons="buttonType=='add'?addButtons:[]">
		<container :model="subPage" :project="project" :page="page" :host="host" :isedit="isedit" :datas="{}" />

	</logicdialog>
</template>

<script>
	/* 
			oneRoot: {
				type: Boolean,
				default: true
			},		
			
			 
			barHeight: {
				type: String,
				default: 'auto'
			},
*/
	import Treeview from '../../../../comm/treeview/TreeView.vue'
	import formBase from '../../../formbase.js'
	export default {
		mixins: [formBase],
		data() {
			return {
				treeObj: null,
				checkedData: null,
				buttonType: '',
				subPage: null,
				formModel: null,
				addButtons: [{
					"key": "next",
					"text": "添加并继续",
					"icon": "far fa-plus",
					"type": "success",
					"enabled": "Y",
					"loading": "N"
				}]

			}
		},
		computed: {
			editButtons() {
				let model = this.model
				let rs = ''
				if (model.allowAdd == 'Y') {
					rs = rs + ',add'
				}
				if (model.allowEdit == 'Y') {
					rs = rs + ',rename'
				}
				if (model.allowDelete == 'Y') {
					rs = rs + ',delete'
				}
				if (rs.length > 1) {
					rs = rs.substring(1)
				}
				return rs
			},
			checkedNodes() {
				let rs = []
				let keys = this.model.checked
				if (keys) {
					if (typeof(keys) == 'string') {
						rs = keys.split(',')
					} else {
						rs = keys
					}
				}
				return rs
			},
			expandedNodes() {
				let rs = []
				let keys = this.model.expanded
				if (keys) {
					if (typeof(keys) == 'string') {
						rs = keys.split(',')
					} else {
						rs = keys
					}
				}
				return rs
			},
			nodes() {
				let rs = []
				let nodeMap = {}
				let list = this.dataList
				for (let data of list) {
					nodeMap[data.id] = {
						id: data.id,
						parent: null,
						label: data.label,
						icon: data.icon,
						color: data.color,
						children: [],
						data: data.$data || data.data //自动加载的必带$data,兼容表态数据的对象数据取值
					}
				}
				for (let item of list) {
					let key = item.id
					let pkey = item.pid
					let node = nodeMap[key]

					let pnode = nodeMap[pkey]
					if (pnode) {
						node.parent = pnode
						pnode.children.push(node)
					} else {
						rs.push(node)
					}
				}

				return rs
			}
		},

		methods: {
			buttonClick(button) {
				if (button.key == 'next') {
					this.formEditDialogOk(true)
				}

			},
			formEditDialogOk(next) {
				let page$ = this.subPage.$getPage().$
				let form = page$('.form')[0]
				form.$submit((res) => {
					if (res.code == 0) {
						this.model.$dataLoad(null, list => {
							this.clickNode(res.data)
						})
						if (next === true) {
							this.subPage.$fresh()
						} else {
							this.$refs.formEditDialog.hide()
						}

						this.$logic.tip.success(form.tip)
					} else {
						this.$logic.tip.error('提交失败：' + res.info)
					}
				})

			},

			formEditDialogClose() {
				this.$logic.pop.confirm('确认', '是否确定取消编辑', () => {

					let formPage$ = this.subPage.$getPage().$
					let forms = formPage$('.form')
					if (forms.length < 1) {
						this.$refs.formEditDialog.hide()
						return
					} else {
						let form = forms[0]
						form.$clearValidate()
						this.$refs.formEditDialog.hide()
					}

				})

			},
			initSubPage() {
				if (this.isedit || this.model.autoManage != 'Y') {
					return
				}
				if (!this.model.pageKey) {
					this.$logic.tip.error('已启用自动数据维护功能，但并未设置编辑页面')
					return
				}

				this.formModel = null
				let pageKey = this.model.pageKey
				let x = pageKey.indexOf('?')
				if (x > 0) {
					pageKey = pageKey.substring(0, x)
				}
				let params = {
					appId: this.page.appId,
					appVersion: this.page.appVersion,
					formKey: pageKey
				}

				this.$logic.http.pageLoad(this.project, this.elementConfig, null, params, res => {
					let pageModel = res.formData
					let forms = this.$logic.pageUtil.getElements(pageModel.items, '.form', true)
					if (forms.length < 1) {
						this.$logic.tip.error('指定的表单页面中不存在表单容器')
						return
					}
					let formItem = forms[0]
					if (!formItem.idField || !formItem.idField.fieldName) {
						this.$logic.tip.error('请设置数据编辑表单容器的表单数据库Id字段')
						return
					}
					//this.pageModel = pageModel
					this.formModel = formItem
					let subPage = this.elementConfig.create('subpage', null, this.page.formData)
					subPage.parent = this.model
					subPage.url = null
					this.subPage = subPage

				}, err => {
					this.formModel = null
					this.$logic.tip.error('表单加载失败 ' + err.info)
				}, this.isedit)
			},


			clickNode(nodeId) { //选中指定ID的节点，并触发点击事件
				this.$refs.treeView.nodeClick(nodeId)
			},
			nodeClick(nodeObj) {


				let node = nodeObj.data
				let subNodes = []
				let subIds = []
				let stack = []
				if (this.model.subSelf == 'N') { //不排除自己
					stack.push(node)
				} else {
					if (node.children) {
						for (let item of node.children) {
							stack.push(item)
						}
					}
				}
				while (stack.length > 0) {
					node = stack.pop()
					subIds.push('' + node.id) //字符串形式，以实现Id兼容字符串类型的
					subNodes.push(node)
					if (node.children) {
						for (let item of node.children) {
							stack.push(item)
						}
					}
				}

				let subIdRs = null
				if (this.isedit) {

					this.model.currentNode = JSON.stringify(nodeObj.data, (k, v) => {
						if (k == 'children' || k == 'parent') {
							return
						} else {
							return v
						}
					})
					subIdRs = JSON.stringify(subIds)
					if (!this.isCurrent) {
						this.mouseClick(null)
					}

				} else {
					this.model.currentNode = nodeObj.data
					subIdRs = subIds
					this.doEvent({
						eventName: 'nodeClick',
						parentNode: nodeObj.data,
						subItems: subNodes,
						nodeData: nodeObj
					}, '$comp_nodeclick')
				}
				let idPath = nodeObj.idPath
				let labelPath = nodeObj.textPath
				let side = this.model.idSide
				let split = this.model.splitChar
				if (side === 'head') {
					idPath = split + idPath
				} else if (side === 'tail') {
					idPath = idPath + split
				} else if (side === 'both') {
					idPath = split + idPath + split
				}
				side = this.model.labelSide
				if (side === 'head') {
					labelPath = split + labelPath
				} else if (side === 'tail') {
					labelPath = labelPath + split
				} else if (side === 'both') {
					labelPath = split + labelPath + split
				}
				this.setData('nodeId', nodeObj.data.id)
				this.setData('nodeLabel', nodeObj.data.label)
				this.setData('subIds', subIdRs)

				this.setData('idPath', idPath)
				this.setData('labelPath', labelPath)
			},
			setEditPage(pid, id, parentData, dataItem) {
				let model = this.model
				if (this.formModel) {
					let param = this.formModel.idParam
					let url = model.pageKey + (model.pageKey.indexOf('?') > 0 ? '&' : '?') + param + '=' + id
					url = url + '&' + model.pidParam + '=' + pid
					if (model.exParam && model.exParam.length > 0 && dataItem.data) {
						for (let key of model.exParam.split(',')) {
							key = key.trim()
							if (key) {
								let value1 = key in dataItem.data ? dataItem.data[key] : ''
								let value2 = key in parentData.data ? parentData.data[key] : ''

								url = url + '&' + key + '=' + value1
								url = url + '&$' + key + '=' + value2
							}
						}
					}
					url = url + '&$ticksequence=' + new Date().getTime()
					//console.log(url)
					this.subPage.url = url
					this.$refs.formEditDialog.show()

				} else {
					this.$logic.tip.error('已设置数据自动维护模式，但未获取到编辑页面中的表单容器对象,请确认编辑页面的设置')
				}
			},
			nodeAdd(nodeObj, newName) {

				if (!this.isedit) {
					this.buttonType = 'add'
					let parentData = nodeObj ? nodeObj.data : this.getNewNodeData()

					let model = this.model
					if (model.autoManage == 'Y' && model.pageKey) {
						this.setEditPage(parentData.id, 0, parentData, {
							data: {}
						})

					} else {

						let rs = this.doEvent({
							eventName: 'nodeAdd',
							parentNode: parentData

						}, '$comp_nodeadd')
					}
				}
			},
			getNewNodeData() {
				return {
					id: '0',
					pid: null,
					label: '',
					data: {}
				}
			},
			nodeRename(nodeObj, newName) {
				if (!this.isedit) {
					this.buttonType = 'edit'
					let model = this.model
					if (model.autoManage == 'Y' && model.pageKey) {

						let parent = nodeObj.data.parent
						if (parent == null) {
							parent = this.getNewNodeData()
						}
						this.setEditPage(parent.id, nodeObj.data.id, parent, nodeObj.data)

					} else {
						let rs = this.doEvent({
							eventName: 'nodeRename',
							targetNode: nodeObj.data,
							/* nodeData: {
								label: newName
							} */
						}, '$comp_noderename')
					}

				}
			},

			nodeDelete(nodeObj) {

				if (!this.isedit) {
					this.buttonType = 'delete'
					let model = this.model
					let check = this.doEvent({
						eventName: 'deleteBefore',
						targetNode: nodeObj.data,
					}, '$comp_deletebefore')


					if (check === false) {
						return
					}
					if (model.autoManage == 'Y' && this.formModel) {

						let id = nodeObj.data.id
						let pid = nodeObj.data.pid
						let table = this.formModel.dbTable
						let formField = this.formModel.idField.fieldName
						let sqlItem = {
							sql: 'delete from ' + table + ' where ' + formField + '=?',
							params: [id]
						}
						this.page.$sql.do([sqlItem]).then(res => {
							this.model.$dataLoad(null, list => {
								this.clickNode(pid)
							})
						}).catch(err => {
							this.$logic.tip.error('删除失败：' + err.info)
						})

					} else {
						let rs = this.doEvent({
							eventName: 'nodeDelete',
							targetNode: nodeObj.data,
						}, '$comp_nodedelete')

					}
				}

			},
			checkChange(node, checkedObj) {
				if (!this.isedit) {
					this.checkedData = checkedObj
					this.doEvent({
						eventName: 'nodeCheck',
						checked: checkedObj
					}, '$comp_nodecheck')
				}
			},
			allowDrop(draggingNode, dropNode, type) {
				if (!this.isedit) {
					let rs = this.doEvent({
						eventName: 'checkDrop',
						drop: {
							draggingNode: draggingNode.data,
							targetNode: dropNode.data,
							type
						}
					}, '$comp_checkdrop')
					if (rs === false) {
						return false
					} else {
						return true
					}
				} else {
					return false
				}

			},
			nodeDrop(draggingNode, dropNode, type, event) {

				if (!this.isedit) {
					this.doEvent({
						eventName: 'nodeDrop',
						drop: {
							draggingNode: draggingNode.data,
							targetNode: dropNode.data,
							type
						}
					}, '$comp_nodedroped')

				}

			},
			init(treeObj) {
				this.treeObj = treeObj
				this.treeObj.allowDrop = this.allowDrop
			}

		},
		created() {
			if (this.isedit) { //初始化时始终为空集合
				//this.model.subIds = '[]'
			} else {
				//this.model.subIds = []
				this.model.$nodeClick = this.clickNode
				this.initSubPage()
			}


		},
		components: {
			treeview: Treeview
		}

	}
</script>

<style scoped>

</style>