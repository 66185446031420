import compBase from '../../public/comp.js'
import font from '../../public/font.js'
import extend from '../../public/extend.js'
import options from '../../public/options.js'
let datas=null //JSON.stringify([	{},{},{},{},{},{},{},{}])
let colls=[{label:'折叠',value:'collapse'},{label:'不折叠',value:''}] 
let oprations=[{label:'编辑',value:'edit'},{label:'只读',value:'read'}]//,{label:'文本',value:'text'},{label:'隐藏',value:'hide'}] 
let idConfig=compBase.getDataConfig()
let pidConfig=compBase.getDataConfig()
let cs=[
{key:'title',text:'表格属性'},
//{key:'opration',text:'操作模式',type:'select',init:'edit',target:'',bursh:true,bind:true,tip:'取值：edit:编辑模式，read:只读模式',list: oprations},
{key:'status',text:'操作模式',type:'select',init:'edit',target:'',bursh:true,bind:true,tip:'取值：edit:编辑模式，disabled:只读模式',list: [{label:'可编辑',value:'edit'},	{label:'只读',value:'disabled'}]},

//{key:'colSize',text:'表格列数',type:'number',init:10,target:'',bursh:true,bind:true,tip:'',list: null},
	{key: 'save',text: '提交保存',type: 'switch',init: 'Y',target: '',bursh: true,bind: true,tip: '是否将此组件数据提交写入到数据库',list: null},
{key:'dbTable',text:'数据库表名',type:'table',init:'',target:'',bursh:true,bind:false,tip:'指定保存到数据库的表名',list: null},
{key: 'idField',text: 'Id字段',type: 'field',init: idConfig,	target: '',	bursh: true,bind: false,tip: '指定保存到数据表的ID字段名(自增长型)，必须设置',host:'dbTable'},
{key: 'pidField',text: '父表Id字段',type: 'field',init: pidConfig,	target: '',	bursh: true,bind: false,tip: '作为子表关联父表ID的字段（如表单ID），如果不存在父表，可不设置',host:'dbTable'},
{key:'idParam',text:'表单ID参数',type:'text',init:'formId',target:null,bursh:true,bind:true,tip:'如果是表单下的子表数据，需指定表单ID值的参数名（区分大小写），默认参数名为formId',list:null},
{key: 'dataTo',text: '组件传值',type: 'text',init: '',	target: '',	bursh: true,bind: true,tip: '列表数据给其它组件传值，例如向另一个列表组件传值，数据加载完成后触发，多组件ID请用逗号分隔，格式：e1,e2,e3',list: null},
{key:'enterType',text:'回车键行为',type:'select',init:'toright',target:'',bursh:true,bind:true,tip:'',list: options.toOptions("移到下行当前列=tonext,移到下行第一列=tofirst,当前行下插入新行=addnext,最后行下追加新行=addlast,当前列向右切换=toright")},

{key:'initRows',text:'初始行数',type:'number',init:5,target:'',bursh:true,bind:true,tip:'无数据时空行数量',list: null},
{key:'minW',text:'最小宽度',type:'text',init:'calc(100% - 4px)',target:'minWidth',bursh:true,bind:true,tip:'用于兼容移动端宽度自适应,象素、百分比、函数类型',list:null},

{key:'headHeight',text:'表头行高',type:'text',init:'30px',target:'--head-height',bursh:true,bind:true,tip:'',list: null},

{key:'rowHeight',text:'表体行高',type:'text',init:'30px',target:'--row-height',bursh:true,bind:true,tip:'',list: null},
{key:'showFoot',text:'显示表尾',type:'select',init:'Y',target:'',bursh:true,bind:true,tip:'',list: options.YON},

//{key:'tdP',text:'内间距',type:'text',init:'10px',target:'',bursh:true,bind:true,tip:'',list: null},
{key:'lineShow',text:'显示网格线',type:'select',init:'Y',target:'',bursh:true,bind:true,tip:'',list: options.YON},
//{key:'lineSize',text:'网格线粗',type:'number',init:'1',target:'',bursh:true,bind:true,tip:'',list: null},
{key:'lineColl',text:'线条折叠',type:'select',init:'collapse',target:'border-collapse',bursh:true,bind:true,tip:'',list: colls},
{key:'hoverColor',text:'悬浮颜色',type:'colorA',init:'#F2F6FC',target:'--hover-color',bursh:true,bind:true,tip:'选择颜色',list:null},	 
{key:'lineColor',text:'斑马线颜色',type:'colorA',init:'#fafafa',target:'--line-color',bursh:true,bind:true,tip:'选择颜色',list:null},	 


//{key:'width',text:'宽度',type:'text',init:'calc(100% - 0px)',target:'width',bursh:true,bind:true,tip:'用于兼容移动端宽度自适应,象素、百分比、函数类型',list:null},
{key:'height',text:'高度',type:'text',init:'calc(100% - 2px)',target:'height',tip:'象素、百分比、函数类型',bursh:true,bind:true,list:null},	
{key:'minH',text:'最小高度',type:'text',init:'100px',target:'minHeight',tip:'象素、百分比、函数类型',bursh:true,bind:true,list:null},	
{key:'overH',text:'水平滚动',type:'select',init:'auto',target:'overflowX',tip:'容器内水平方向溢出时',bursh:true,bind:true,list:scroll},	
{key:'overV',text:'垂直滚动',type:'select',init:'auto',target:'overflowY',tip:'容器内垂直方向溢出时',bursh:true,bind:true,list:scroll},	
{key:'borderS',text:'边框线型',type:'select',init:'solid',target:'borderStyle',bursh:true,bind:true,tip:'无边框:none,实线:solid,虚线:dashed,点线:dotted,双实线:double,立体凸线:outset,立体凹线:inset',list:options.BorderType},	
{key:'borderL',text:'左边框',type:'text',init:'1px',target:'borderLeftWidth',bursh:true,bind:true,tip:'边框线粗，象素',list:null},	
{key:'borderT',text:'上边框',type:'text',init:'1px',target:'borderTopWidth',bursh:true,bind:true,tip:'边框线粗，象素',list:null},	
{key:'borderR',text:'右边框',type:'text',init:'1px',target:'borderRightWidth',bursh:true,bind:true,tip:'边框线粗，象素',list:null},	
{key:'borderB',text:'下边框',type:'text',init:'1px',target:'borderBottomWidth',bursh:true,bind:true,tip:'边框线粗，象素',list:null},	
{key:'borderColor',text:'边框颜色',type:'color',init:'#cccccc',target:'borderColor',bursh:true,bind:true,tip:'选择颜色',list:null},	 

]
 let events=[
	 {key: '$comp_sql',text: 'SQL自定义',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '系统默认根据列字段关系自动生成SQL，支持用户指定SQL，在此函数中返回SQL字符串，字段名大小写必须与列中配置的字段名一致',list: null	},
	
	 {key: '$comp_loadBefore',text: '列表加载前',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '数据加载前触发，可进行数据预处理,返回值：SQL字符串，如有返回值，即以此SQL作为列表的查询语句，否则采用自动查询方式',list: null	},
	 {key: '$comp_loadAfter',text: '列表加载后',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '数据加载完成后触发，可进行特定业务逻辑处理',list: null	},
	 {key: '$comp_rowChange',text: '列表行增减',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '数据增加、删除行后触发',list: null	},
	 {key: '$comp_blurBefore',text: '焦点切换前',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '当前活动单元格失去焦点前触发,可进行公式计算前的数据处理，返回值为例名或不返回，如返回列名将触发此列相关的公式计算',list: null	},
	 {key: '$comp_blurAfter',text: '焦点切换后',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '当前活动单元格失去焦点后触发，可于公式计算完成后的数据处理',list: null	},
	 {key: '$comp_total',text: '汇总计算后',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '汇总数据计算后触发，可获取各列汇总值作相关处理',list: null	},
    {key: '$comp_change',text: '值更新后',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '单元格内值发生变化后',list: null	},
        
	// {key: '$comp_popAfter',text: '弹出选值后',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '日期选择框、联想输入框、树型、树列表这类弹出式',list: null	},
options.listLoadAfterEvents.dataListEvent,
 ] 
  

let inputlist= {
	data:{
		type:'inputlist',display:'',isInput:'T',notCreate:true
	},
	delete:'',
	hide:'width,height,minH,overH,overV,lineColl,borderS,borderL,borderT,borderR,borderB,borderColor',
	base:cs.concat(font) ,
	special:[],// [	{key: 'listData',text: '表格行数据',type: 'json',init: datas,	target: '',bursh:false,bind:true,tip: '',},],

	action:events.concat(options.getEvents(null,'$hook'))
}

export default inputlist