import Config from '../config.js'
 
let dataListAfterEvent=	{key: '$comp_listLoadAfter',text: '动态加载后',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '自动数据加载完成后触发，可进行数据的拦截处理',list: null	}
let menuListAfterEvent=	{key: '$comp_menueLoadAfter',text: '菜单项加载后',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '菜单项数据加载完成后触发，可进行数据的拦截处理',list: null	}
let listLoadAfterEvents={
	dataListEvent:dataListAfterEvent,
	menuListEvent:menuListAfterEvent
} 
let timers = [
	//{key: 'title',text: '定时任务'	},
	{
		key: 'loadAllow',
		text: '动态加载',
		type: 'switch',
		init: 'N',
		target: '',
		bursh: true,
		bind: true,
		tip: '只有开启状态下才调用API或SQL动态数据加载，可编程loadAllow(Y/N)控制 关闭/开启',
		list: null
	},
	{
		key: 'timerSplit',
		text: '定时（秒）',
		type: 'number',
		init: 0,
		target: '',
		bursh: true,
		bind: true,
		tip: '每隔指定的秒数后执行一次,可以为小数，大于0时定时器生效',
		list: null
	},
	/* 	{
			key: 'timerAutoLoad',
			text: '自动装配',
			type: 'switch',
			init: 'N',
			target: '',
			bursh: true,
			bind: false,
			tip: '自动根据接口地址加载数据填充到容器内部的所有元素',
			list: null
		}, */
	{
		key: 'timerUrl',
		text: 'API地址',
		type: 'url',
		init: '',
		target: '',
		bursh: true,
		bind: true,
		tip: '系统内接口使用相对路径，系统外绝对路径,有效长度不少于10个字符，组件创建时执行一次API或SQL,如果同时设置优先执行API忽略SQL',
		list: null
	},
	/* {
		key: 'dataSource',
		text: '数据源',
		type: 'source',
		init: 0,
		target: '',
		bursh: true,
		bind: false,
		tip: '只对SQL查询方式有效，指定使用哪个数据源，默认为当前应用系统的数据库',
		list: null
	},
	{
		key: 'timerSql',
		text: '查询SQL',
		type: 'area',
		init: '',
		target: '',
		bursh: true,
		bind: true,
		tip: '支持快速SQL直接数据库取数，如未指定API即执行此SQL,有效长度不少于10个字符，组件创建时执行一次API或SQL',
		list: null
	}, */
	{
		key: 'timerSqlFormat',
		text: '数组格式',
		type: 'switch',
		init: 'Y',
		target: '',
		bursh: true,
		bind: false,
		tip: '执行SQL返回数组或Json格式的数据，API接口返回数据格式以接口中设定的为准',
		list: null
	},
	{
		key: 'timerSqlLimit',
		text: '数据量上限',
		type: 'number',
		init: 10000,
		target: '',
		bursh: true,
		bind: false,
		tip: '执行SQL最大数据量上限，API接口返回数据量以接口中设定的为准',
		list: null
	},
	{
		key: 'timerLayer',
		text: '加载时遮罩',
		type: 'switch',
		init: 'N',
		target: '',
		bursh: true,
		bind: false,
		tip: '数据请求时是否遮罩页面等待返回',
		list: null
	},

	{
		key: '$timer_queryBefor',
		text: '接口请求前',
		type: 'event',
		init: 'N',
		target: '',
		bursh: true,
		bind: false,
		tip: '调用接口或执行SQL前，可用于动态处理请求参数，返回false将取消本次数据请求，内部应使用同步方法',
		list: null
	},
	{
		key: '$timer_queryAfter',
		text: '请求成功',
		type: 'event',
		init: 'N',
		target: '',
		bursh: true,
		bind: false,
		tip: '成功调用接口或执行SQL后，可用于拦截处理接口返回的数据或设置元素状态等',
		list: null
	},
	{
		key: '$timer_queryError',
		text: '请求失败',
		type: 'event',
		init: 'N',
		target: '',
		bursh: true,
		bind: false,
		tip: '调用接口或执行SQL异常后，可用于用户错误提示或设置元素状态等',
		list: null
	},
	{
		key: '$timer_befor',
		text: '任务执行前',
		type: 'event',
		init: 'N',
		target: '',
		bursh: true,
		bind: false,
		tip: '定时任务前执行，返回false将取消本次任务，执行顺序在数据请求前，内部应使用同步方法',
		list: null
	},
	{
		key: '$timer_after',
		text: '任务执行后',
		type: 'event',
		init: 'N',
		target: '',
		bursh: true,
		bind: false,
		tip: '接口请求后的事件后执行',
		list: null
	},

]
let events = [
	//{key: 'title',text: '用户事件'	},
	{
		key: '$event_click',
		text: '鼠标单击',
		type: 'event',
		init: 'N',
		target: '',
		bursh: true,
		bind: false,
		tip: '只响应鼠标左键点击',
		list: null
	},
/* 	{
		key: '$event_dblclick',
		text: '鼠标双击',
		type: 'event',
		init: 'N',
		target: '',
		bursh: true,
		bind: false,
		tip: '只响应鼠标左键点击',
		list: null
	},
	{
		key: '$event_mousedown',
		text: '鼠标按下',
		type: 'event',
		init: 'N',
		target: '',
		bursh: true,
		bind: false,
		tip: '响应所有鼠标键点击，可设置限定鼠标键',
		list: null
	},
	{
		key: '$event_mouseup',
		text: '鼠标弹起',
		type: 'event',
		init: 'N',
		target: '',
		bursh: true,
		bind: false,
		tip: '响应所有鼠标键点击，可设置限定鼠标键',
		list: null
	},
	{
		key: '$event_mouseenter',
		text: '鼠标移入',
		type: 'event',
		init: 'N',
		target: '',
		bursh: true,
		bind: false,
		tip: '鼠标进入后触发',
		list: null
	},
	{
		key: '$event_mousemove',
		text: '鼠标移动',
		type: 'event',
		init: 'N',
		target: '',
		bursh: true,
		bind: false,
		tip: '鼠标在此元素上移动时触发',
		list: null
	},
	{
		key: '$event_mouseleave',
		text: '鼠标移出',
		type: 'event',
		init: 'N',
		target: '',
		bursh: true,
		bind: false,
		tip: '鼠标离开此元素后触发',
		list: null
	},
	{
		key: '$event_mouseover',
		text: '鼠标悬停',
		type: 'event',
		init: 'N',
		target: '',
		bursh: true,
		bind: false,
		tip: '鼠标放在此元素上时触发',
		list: null
	}, */
	{
		key: '$event_keypress',
		text: '键盘敲击',
		type: 'event',
		init: 'N',
		target: '',
		bursh: true,
		bind: false,
		tip: '只响应有可见内容输入的键输入',
		list: null
	},
	{
		key: '$event_keydown',
		text: '键盘按下',
		type: 'event',
		init: 'N',
		target: '',
		bursh: true,
		bind: false,
		tip: '响应所有键盘按键',
		list: null
	},
	{
		key: '$event_keyup',
		text: '键盘弹起',
		type: 'event',
		init: 'N',
		target: '',
		bursh: true,
		bind: false,
		tip: '响应所有键盘弹起',
		list: null
	},
	{
		key: '$event_change',
		text: '值发生变化',
		type: 'event',
		init: 'N',
		target: '',
		bursh: true,
		bind: false,
		tip: '内容发生变化后解发，文本框元素失去焦点后触发',
		list: null
	},
	{
		key: '$event_input',
		text: '内容输入',
		type: 'event',
		init: 'N',
		target: '',
		bursh: true,
		bind: false,
		tip: '文本框内容发生变化立即触发',
		list: null
	},
	{
		key: '$event_focus',
		text: '获取焦点',
		type: 'event',
		init: 'N',
		target: '',
		bursh: true,
		bind: false,
		tip: '获取焦点后触发',
		list: null
	},
	{
		key: '$event_blur',
		text: '失去焦点',
		type: 'event',
		init: 'N',
		target: '',
		bursh: true,
		bind: false,
		tip: '失去焦点后触发',
		list: null
	},
	{
		key: '$event_scroll',
		text: '滚动条滚动',
		type: 'event',
		init: 'N',
		target: '',
		bursh: true,
		bind: false,
		tip: '容器滚动条滚动时触发',
		list: null
	},
	//有的浏览器不直接提供此事件，先不加大小变化事件
	//{key: '$event_resize',text: '大小变化',type: 'event',init: 'N',target: '',bursh: true,bind: false,tip: '容器宽、高大小发生变化后触发',list: null	},



]
let hooks = [
	//{key: 'title',text: '系统事件'	},
	{
		key: '$hook_create',
		text: '创建前',
		type: 'event',
		init: 'N',
		target: '',
		bursh: true,
		bind: false,
		tip: '元素创建前触发,此时容器内的子组件尚未创建，无法进行子组件的功能调用',
		list: null
	},
	{
		key: '$hook_mount',
		text: '挂载后',
		type: 'event',
		init: 'N',
		target: '',
		bursh: true,
		bind: false,
		tip: '元素页面渲染后触发',
		list: null
	},
	{
		key: '$hook_show',
		text: '显示前',
		type: 'event',
		init: 'N',
		target: '',
		bursh: true,
		bind: false,
		tip: '元素变为可见状态时触发',
		list: null
	},
	{
		key: '$hook_hide',
		text: '隐藏前',
		type: 'event',
		init: 'N',
		target: '',
		bursh: true,
		bind: false,
		tip: '元素变为不可见状态时触发',
		list: null
	},
	{
		key: '$hook_destroy',
		text: '销毁前',
		type: 'event',
		init: 'N',
		target: '',
		bursh: true,
		bind: false,
		tip: '元素销毁前触发',
		list: null
	},
]
let funcGroup = {
	$timer: {
		label: '定时任务事件',
		icon: 'far fa-alarm-clock'
	},
	$comp: {
		label: '组件业务事件',
		icon: 'far fa-file-chart-line'
	},
	$event: {
		label: '通用交互事件',
		icon: 'far fa-mouse' //''
	},
	$hook: {
		label: '生命周期事件',
		icon: 'far fa-draw-circle'
	},
	$user: {
		label: '用户自定义函数',
		icon: 'far fa-function'
	}
}
let privateEvent = { //组件内部实现，BaseElement 组件公共实现方法中需排除的
	'button': '$event_click',
	'label': '$event_click',
	'link': '$event_click',
	'icon': '$event_click',
	'image': '$event_click',
	'tag': '$event_click',
	'smscode': '$event_click',



}
//用于动态生成事件,通用事件绑定到容器页面的事件变量
let getEventMap = (element, obj) => {
	let eventMap = {}
	for (let i = 0; i < events.length; i++) { //初始化空值，防止没有设置过事件的元素返回空出现空值异常
		eventMap[events[i].key] = {
			name: null,
			handler: null
		}
	}
	//获取元素定义的所有event类型事件	
	let configs = Config.objs[element.type].configs//注意：老页面中如含有已废除的组件，这个地方会报错null
	for (let config of configs) {
		let evt = config.key
		if (evt.indexOf('$event_') < 0) {
			continue
		}
		let functions = element.$functions || {}
		let func = functions[evt]
		if (func) {
			let a = evt.indexOf('_')
			let evtName = evt.substring(a + 1)
			let evtSet = element.$events[evt] //一定存在			
			func.mouseButton = evtSet.mouseButton
			func.keyCodes = evtSet.keyCodes
			func.withKeys = evtSet.withKeys
			func.group = evtSet.group
			eventMap[evt] = {
				name: evtName,
				handler: obj[evt]
			}
			//console.log(eventMap[evt])
		} else {
			//eventMap[evt]={name:null,handler: null} 已在前面初始化过空值
		}
	}

	return eventMap

}
//eventList额外附加的事件集体，eventNames名称逗号隔的字符串或数组,removeNames 需排除的事件名称集，根据eventNames中的顺序排列组顺序,eventNames中含$timer表示加上定时器的全部配置项
let getEvents = (eventList, eventNames, removeNames) => {
	let es = [].concat(events).concat(hooks)
	if (eventList) {
		es.concat(eventList)
	}
	let allEvent = {} //key为key,值为配置项
	let evtMap = {} //组为key,值数组
	let groupNames = [] //用于记录组出现顺序
	for (let evt of es) {
		if (evt.type) { //确保是有效配置项
			allEvent[evt.key] = evt
		}
	}
	//取出所有指定的有效事件
	let evts = (typeof(eventNames) == 'string' ? eventNames.split(',') : eventNames)
	for (let evt of evts) {

		let a = evt.indexOf('_') //取出前缀
		let groupName = a > 0 ? evt.substring(0, a) : evt

		let eventGroup = evtMap[groupName]
		if (eventGroup) {

		} else {
			eventGroup = []
			evtMap[groupName] = eventGroup
			groupNames.push(groupName)

		}
		if (a > 0) {
			let item = allEvent[evt]
			if (item) {
				eventGroup.push(item)
			}
		} else { //如果是组名，装入整组
			if (groupName == '$timer') {
				evtMap[groupName] = eventGroup.concat(timers)
			} else if (groupName == '$event') {
				evtMap[groupName] = eventGroup.concat(events)
			} else if (groupName == '$hook') {
				evtMap[groupName] = eventGroup.concat(hooks)
			}
		}



	}
	//处理排除项
	let rmMap = {}
	if (removeNames) {
		let rms = (typeof(removeNames) == 'string' ? removeNames.split(',') : removeNames)
		for (let rm of rms) {
			rmMap[rm] = rm
		}
	}

	let rs = []
	for (let gName of groupNames) {
		let group = evtMap[gName]
		rs.push({
			key: 'title',
			text: funcGroup[gName].label
		})
		for (let item of group) {
			if (rmMap[item.key]) {

			} else {
				rs.push(item)
			}

		}
	}


	return rs
}
let getEvent = (el, eventName) => {

	if (!el || !el.$functions) {
		return null
	}
	let evt = el.$functions[eventName]
	if (!evt) {
		return null
	}
	return evt

}
let getGroupEvents = (element, events) => {
	let el = element
	let configs = Config.objs[el.type].configs
	let groupMap = {}
	let groups = []
	for (let config of configs) {
		let key = config.key
		let label = config.text
		let i = key.indexOf('_')
		if (i < 3) {
			continue
		}
		let gname = key.substring(0, i)

		let gDef = funcGroup[gname]
		if (!gDef) {
			continue
		}
		//不存的事件作增补，后期系统中新增的事件添加到原有的实例中 
		if (!events[key]) {
			addNewEvent(events, gname, key, config.text, config.init)
		}
		let group = groupMap[gname]
		if (!groupMap[gname]) {
			group = {
				id: gname,
				label: gDef.label,
				icon: gDef.icon,
				children: [],
				event: null
			}
			groups.push(group)
			groupMap[gname] = group
		}
		let item = {
			id: key,
			label: label,
			icon: '',
			children: null,
			event: events[key]
		}
		group.children.push(item)
	}
	let userDef = funcGroup['$user']
	if (!groupMap['$user']) { //固定显示用户函数组
		let userGroup = {
			id: 'user',
			label: userDef.label,
			icon: userDef.icon,
			children: []
		}
		groups.push(userGroup)
	}
	return groups
}
let initEvents = (element) => { //从组件事件定义配置中生成函数对象，$user_funcs：用户函数，$funcs：可运行的函数对象
	let eventBag = {}
	let configs = Config.objs[element.type].configs
	for (let config of configs) {
		let key = config.key
		let label = config.text
		if (!key) {
			alert('错误事件配置项')
			console.log(key,config, configs)
			return
		}
		let i=key.indexOf('_')
		if (key.indexOf('$') !== 0 || i < 3) {
			continue
		}
		let gname = key.substring(0, i)
		let gDef = funcGroup[gname]
		addNewEvent(eventBag, gname, key, config.text, config.init)


	}
	eventBag['$user_funcs'] = []
	eventBag['$funcs'] = {}
	element.$events = eventBag

}
let addNewEvent = (eventBag, group, key, name, status) => {
	eventBag[key] = {
		key: key,
		group: group,
		name: name,
		status: status,
		bubble: 'Y',
		params: ['action'],
		code: '',
		func: null,
		mouseButton: '',
		keyCodes: [],
		withKeys: [] //shift,ctrl,alt
	} //params:[{element:'触发事件的对象元素'},{event},{page},{project},{global}
}
export default {
	getEvents,
	getGroupEvents,
	initEvents,
	getEvent,
	getEventMap,
	privateEvent,
	listLoadAfterEvents,
	commonEvents: '$event_click,$event_dblclick,$event_mousedown,$event_mouseup,$event_mouseenter,$event_mousemove,$event_mouseleave,$event_mouseover'
}
